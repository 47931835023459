import { GamKeyValue, GamPlacement } from './adServers/gam';

export type ContainerId = `${PlacementId}--container`;
export type AdSlot = `${PlacementId}--slot`;

export interface AdPlacementType {
  id: PlacementId;
  containerId: ContainerId;
  slot: AdSlot;
}

type AllAdPlacements = {
  [K in keyof typeof PlacementId]: AdPlacementType;
};

export enum PlacementId {
  Empty = '',
  Top1 = 'advt_top_1',
  Left1 = 'advt_left_1',
  Left2 = 'advt_left_2',
  Right1 = 'advt_right_1',
  Right2 = 'advt_right_2',
  Top2 = 'advt_top_2',
  Middle1 = 'advt_middle_1',
  Middle2 = 'advt_middle_2',
  Middle3 = 'advt_middle_3',
  Middle4 = 'advt_middle_4',
  Middle5 = 'advt_middle_5',
  Bottom1 = 'advt_bottom_1',
  RecircMiddle1 = 'advt_recirc_middle_1',
  RecircMiddle2 = 'advt_recirc_middle_2',
  RecircMiddle3 = 'advt_recirc_middle_3',
  RecircMiddle4 = 'advt_recirc_middle_4',
  RecircMiddle5 = 'advt_recirc_middle_5',
  Wallpaper = 'advt_wallpaper',
  AfsContainer1 = 'afs_container_1',
  AfsContainer2 = 'afs_container_2',
  Financing1 = 'advt_financing_1',
  Audience = 'advt_audience',
  Marketing = 'advt_marketing',
  Article1 = 'advt_article_1',
  Article2 = 'advt_article_2',

  /**
   * These are temporary as they will be phased into the same naming convention as
   * the other NMP placements.
   */
  FinnLegendaryAfsContainer = 'afscontainer1',
  FinnLegendaryTop1 = 'topboard',
  FinnLegendaryRight1 = 'skyscraperright_1',
  FinnLegendaryLeft1 = 'skyscraperleft_1',
  FinnLegendaryWallpaper = 'wallpaper',
  FinnLegendaryRecircMiddle1 = 'ad-stream__native-ad-1',
  FinnLegendaryRecircMiddle2 = 'ad-stream__native-ad-2',
  FinnLegendaryBoard1 = 'board_1',
  FinnLegendaryBoard2 = 'board_2',
  FinnLegendaryBoard3 = 'board_3',
  FinnLegendaryBoard4 = 'board_4',
  FinnLegendaryBoard5 = 'board_5',
  FinnLegendaryNetboard1 = 'netboard_1',
  FinnLegendaryNetboard2 = 'netboard_2',
  FinnLegendaryNetboard3 = 'netboard_3',
  FinnLegendaryNetboard4 = 'netboard_4',
  FinnLegendaryNetboard5 = 'netboard_5',
  FinnContentboard = 'contentboard'
}

export const AdPlacement = Object.fromEntries(
  Object.entries(PlacementId).map(([key, value]) => [
    key as keyof typeof PlacementId,
    {
      id: value,
      containerId: `${value}--container`,
      slot: `${value}--slot`
    }
  ])
) as AllAdPlacements;

export function isForecastingPlacement(placement: GamPlacement) {
  if (!placement) return false;
  // Check forecasting from frontend
  if (placement.forecasting || placement.placementId?.includes('forecasting')) {
    return true;
  }
  const targeting =
    placement?.adServer?.config?.targeting || ([] as GamKeyValue[]);
  // Check targeting from DB
  return !!targeting.some(
    ({ key, value }) => key === 'forecasting' && value === 'true'
  );
}
