import { debounce } from 'throttle-debounce';

import { $config, $initialState } from '@client/core/atoms/config';
import {
  $placementStatusMetrics,
  setInitialPlacementStatusMetrics
} from '@client/core/atoms/metrics';
import fetcher from '@client/core/utils/api/fetcher';
import {
  AdServer,
  debugLog,
  ErrorMetricsBody,
  PlacementId,
  PlacementStatusMetric,
  PlacementStatusMetricsBody
} from '@schibsted-nmp/advertising-shared';

import { getPlacementById, getPlacementList } from '../atoms/placements';

const debouncedSendMetricsFunction = debounce(3000, () => {
  sendMetricsToServer();
});

export function debouncedSendMetrics() {
  debouncedSendMetricsFunction();
}
export function getDebouncedSendMetricsFunction() {
  return debouncedSendMetrics;
}

function getAdServerByPlacementId(
  placementId: PlacementId
): AdServer | undefined {
  const placement = getPlacementById(placementId);
  return placement?.adServer.type;
}

export async function sendMetricsToServer() {
  const { pageType, deviceType, vertical, subvertical } = $config.get();

  const placementStatuses: PlacementStatusMetric[] = [];
  $placementStatusMetrics.get().forEach((placementStatus) => {
    if (
      !placementStatuses.some(
        (ps) => ps.placementId === placementStatus.placementId
      )
    ) {
      placementStatuses.push(placementStatus);
    }
  });

  // send metrics to server
  try {
    await fetcher('/api/metrics/placement-status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        placementStatuses,
        deviceType,
        pageType,
        vertical,
        subvertical,
        adServer: getAdServerByPlacementId(placementStatuses[0].placementId)
      } as PlacementStatusMetricsBody)
    });
    setInitialPlacementStatusMetrics(getPlacementList());
  } catch (e) {
    debugLog('Failed to send metrics to server', e);
  }
}

export async function sendErrorMessageMetricToServer(errorMsg: string) {
  const { pageType, deviceType, vertical, subvertical } = $config.get();
  const { logSessionId } = $initialState.get();

  try {
    if (errorMsg) {
      fetcher(`/api/metrics/client-error`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          logSessionId,
          errorMessage: errorMsg,
          deviceType,
          pageType,
          vertical,
          subvertical
        } as ErrorMetricsBody)
      });
    }
  } catch (e) {
    console.error('Failed to send error message to server', e);
  }
}
