import React from 'react';

import classNames from 'classnames';

import { CustomLabel } from '@client/xandr/components/native/parts/Label';
import { i18n } from '@lingui/core';
import NativeLogo from '@client/xandr/components/native/parts/NativeLogo';
import { NativeAdProps } from '@client/xandr/components/native/NativeAdComponent';
import {
  escapeString,
  getDescription
} from '@client/xandr/components/native/native.helper';
import NativeCogwheel from '@client/xandr/components/native/parts/NativeCogwheel';

/**
 * Native ads on front and object pages are always recommendation ads.
 *
 * The reason this is not being retrieved from search-components is that there
 * are no search components matching this. Recommendations are creating their own components.
 *
 * We could improve this by checking on placementIDs instead of pageType,
 * if we create a util isRecommendationAd(placementId) but then it's string matching.
 */
export const RecommendationNativeAd = ({ adObj, pageType }: NativeAdProps) => {
  const { native } = adObj;
  const adLabel = i18n.t({
    id: 'native.advertisement',
    message: 'advertisement'
  });

  const isFrontPage = pageType === 'front';

  return (
    <article
      className={classNames(
        'relative isolate hover:s-bg-primary-subtle focus:s-bg-primary-subtle rounded-8 h-full',
        {
          'card card--cardShadow': isFrontPage,
          'p-8': !isFrontPage
        }
      )}
    >
      <div
        className="absolute"
        aria-owns="search-ad-native-ad-stream__native-ad-1"
      ></div>
      <div className="mb-4">
        <div className="aspect-1/1">
          <img
            alt={native.title}
            className={classNames(
              'w-full h-full object-center object-cover border s-border',
              {
                'rounded-tl-8 rounded-tr-8': isFrontPage,
                'rounded-8': !isFrontPage
              }
            )}
            src={native.image.url}
          />
        </div>
      </div>
      <div
        className={classNames('flex flex-col', {
          'mx-16 mt-6 mb-14': isFrontPage
        })}
      >
        <div className="flex justify-between mb-4">
          <div className="text-s s-text-subtle">
            {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
          </div>
        </div>
        <div className="flex justify-between items-center gap-2 w-full">
          <h2
            className="h4 mb-0 break-words"
            id="search-ad-native-ad-stream__native-ad-1"
          >
            <a
              className="sf-search-ad-link s-text! hover:no-underline"
              href={native.clickUrl}
              id={native.title}
            >
              <span className="absolute inset-0" aria-hidden="true"></span>
              {native.title}
            </a>
          </h2>
        </div>
        <div className="flex flex-col gap-4">
          <div>{getDescription(native)}</div>
          <NativeLogo
            className="h-auto mt-auto ml-auto"
            constrainWidth={true}
            size={40}
            native={native}
          />
        </div>
      </div>
      <CustomLabel
        adLabel={adLabel}
        insetClassName={classNames({
          'top-0 left-0': isFrontPage,
          'top-8 left-8': !isFrontPage
        })}
      />
      <NativeCogwheel extraInset={isFrontPage ? '0' : '8'} adObj={adObj} />

      <div className="absolute inset-0 pointer-events-none outline-none rounded-8 border-2 border-transparent sf-search-ad-outline"></div>
    </article>
  );
};
