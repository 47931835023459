import { Size, SizePair, SizePairs } from '@schibsted-nmp/advertising-shared';

const MAX_RIGHT_CONTAINER_WIDTH = 300;
const ALLOW_SKYSCRAPER_IN_VIEW_PERCENTAGE = 1;

export function isAdnamiSize(size: Size) {
  return size.width === 1 && size.height === 2;
}

const getRightContainerWidthInViewport = (): number => {
  const container = document.querySelector('.banners__right');
  const rect = container?.getBoundingClientRect();
  if (!rect || rect?.left === 0) return 0;

  const leftEdge = rect.left;
  const rightEdge = leftEdge + MAX_RIGHT_CONTAINER_WIDTH;
  return Math.min(rightEdge, window.innerWidth) - Math.max(leftEdge, 0);
};

export const getRightSkyscraperSizes = (sizes: Size[]): Size[] => {
  const rightContainerWidthInViewport = getRightContainerWidthInViewport();
  return sizes.filter(
    (size) =>
      rightContainerWidthInViewport >=
      size.width * ALLOW_SKYSCRAPER_IN_VIEW_PERCENTAGE
  );
};

export const adSizesToPairs = (adSizes: Size[]): SizePairs =>
  adSizes.map((size) => [size.width, size.height]);

export const adSizePairsToSizes = (adSizePairs: SizePair): Size => ({
  width: adSizePairs[0],
  height: adSizePairs[1]
});
