import React, { useEffect, useState } from 'react';

import {
  $placementsMap,
  getPlacementList
} from '@client/core/atoms/placements';
import {
  AdServer,
  AdType,
  ClientAdPlacement
} from '@schibsted-nmp/advertising-shared';

import { PlacementItem } from './PlacementItem';
import { TabButton } from './TabButton';

export function PlacementsTab() {
  const [placements, setPlacements] = useState<ClientAdPlacement[]>([]);

  useEffect(() => {
    $placementsMap.subscribe(() => {
      setPlacements(getPlacementList());
    });
  }, []);
  const [filterAdServer, setFilterAdServer] = useState<AdServer | null>(null);
  const [filterAdType, setFilterAdType] = useState<AdType | null>(null);

  // Only filter by adServer and creativeType if they exist on the placement
  const filteredPlacements = placements
    .filter(
      (placement) =>
        !filterAdServer || placement.adServer?.type === filterAdServer
    )
    .filter((placement) => {
      if (!filterAdType) return true;
      // Only filter by creativeType if it exists
      return placement.creativeType === filterAdType;
    });

  const adServers = [AdServer.GAM, AdServer.Xandr, AdServer.AFS, AdServer.Adn];

  const adTypes: AdType[] = ['banner', 'native'];
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <h5>Filter by Ad Type:</h5>
        <div className="flex gap-4">
          <TabButton
            label="All"
            active={filterAdServer === null}
            onClick={() => setFilterAdServer(null)}
          />
          {adServers.map((type) => (
            <TabButton
              key={type}
              label={`${type} (${placements.filter((p) => p.adServer.type === type).length})`}
              active={filterAdServer === type}
              onClick={() => setFilterAdServer(type)}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-col">
        <h5>Filter by Creative Type:</h5>
        <div className="flex gap-4">
          <TabButton
            label="All"
            active={filterAdType === null}
            onClick={() => setFilterAdType(null)}
          />
          {adTypes.map((type) => (
            <TabButton
              key={type}
              label={`${type} (${placements.filter((p) => p.creativeType === type).length})`}
              active={filterAdType === type}
              onClick={() => setFilterAdType(type)}
            />
          ))}
        </div>
      </div>

      <div>
        <h5>Placements: ({filteredPlacements.length})</h5>
        <div className="overflow-auto">
          <ul className="flex flex-col gap-4">
            {filteredPlacements
              .sort((a, b) => a.placementId.localeCompare(b.placementId))
              .map((placement) => (
                <PlacementItem
                  key={placement.placementId}
                  placement={placement}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
