import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import {
  debugLog,
  PageOpts,
  PermissionValue,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { $xandrPageOpts } from '@client/core/atoms/xandrPageOpts';
import { $config } from '@client/core/atoms/config';

import { getPageOptsWithExternalIds } from './externalId';
import { setPageOpts } from './xandr';
import { getGlimrKeywords } from '../core/utils/glimr';

export function setPageOptsInXandr(permissionValue: PermissionValue) {
  const { brand } = $config.get();
  let pageOpts = $xandrPageOpts.get() || ({} as PageOpts);

  if (permissionValue === '1') {
    const pageOptsWithEids = getPageOptsWithExternalIds(brand, pageOpts);

    if (pageOptsWithEids) {
      pageOpts = {
        ...pageOpts,
        ...pageOptsWithEids,
        keywords: { ...pageOpts.keywords, ...pageOptsWithEids?.keywords }
      };
    }

    const glimrKeywords = getGlimrKeywords() || {};
    if (glimrKeywords) {
      pageOpts.keywords = { ...pageOpts.keywords, ...glimrKeywords };
    }
  }

  pageOpts.keywords = {
    ...pageOpts.keywords,
    'aa-sch-cmp_advertising': [permissionValue === null ? '0' : permissionValue]
  };

  if (
    isFeatureEnabled(UNLEASH_FEATURE_NAME.enableAdditionalTestKeyValuePageOpts)
  ) {
    pageOpts.keywords = {
      ...pageOpts.keywords,
      'test-nmp-unleash': ['true']
    };
  }

  $xandrPageOpts.set(pageOpts);

  setPageOpts(pageOpts, () => debugLog('pageOpts set', pageOpts));
}
