import {
  Brand,
  DeviceType,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';

export const getRelevantYieldScript = (brand: Brand, device: DeviceType) => {
  if (isFeatureEnabled(UNLEASH_FEATURE_NAME.enableGamPrebid)) {
    const src = getScript(brand, device);

    return {
      name: 'relevant-digital-gam',
      src
    };
  }

  return null;
};

export function getScript(brand: Brand, device: DeviceType): string {
  const scripts = {
    tori: {
      desktop:
        'https://tori-cdn.relevant-digital.com/static/tags/66cecb0e1a3b9040b52acc5f.js',
      mobile:
        'https://tori-cdn.relevant-digital.com/static/tags/66cecb0e1a3b9040b52acd7c.js'
    },
    finn: {
      desktop: '',
      mobile: ''
    },
    dba: {
      desktop: '',
      mobile: ''
    },
    blocket: {
      desktop: '',
      mobile: ''
    }
  };
  if (scripts[brand] && scripts[brand][device]) {
    return scripts[brand][device];
  }
  return '';
}

export function getConfigId(brand: Brand, device: DeviceType): string {
  const configs = {
    tori: {
      desktop: '66d5ac2aed0ef9bb84ce3fe9',
      mobile: '66dea8dd13855042b642272c'
    },
    finn: {
      desktop: '',
      mobile: ''
    },
    blocket: {
      desktop: '',
      mobile: ''
    },
    dba: {
      desktop: '',
      mobile: ''
    }
  };
  if (configs[brand] && configs[brand][device]) {
    return configs[brand][device];
  }
  return '';
}
