import { useEffect, useState } from 'react';

import { useStore } from '@nanostores/react';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

import { $cycleAtom } from '../atoms/cycleAtom';

export function useCycleAd(placementId: PlacementId): string {
  const [placementIdCycle, setPlacementIdCycle] = useState<string>(placementId);
  const cycle = useStore($cycleAtom);
  useEffect(() => {
    if (cycle !== 0) {
      setPlacementIdCycle(placementId + cycle);
    }
  }, [cycle]);

  return placementIdCycle;
}
