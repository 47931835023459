import { html } from 'lit';

import {
  AdServer,
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';
import { renderAd } from '@client/xandr/components/renderAd';
import { AdVendor } from '@client/core/AdVendor';
import { $config } from '@client/core/atoms/config';

export class XandrAdVendor extends AdVendor {
  constructor(
    placementId: PlacementId,
    containerId: string,
    adIndex: number,
    initialLayoutType: LayoutType,
    categories?: RecommendationCategories
  ) {
    super();
    this.adServer = AdServer.Xandr;
    this.placementId = placementId;
    this.containerId = containerId || `${placementId}--container`;
    this.initialLayoutType = initialLayoutType;
    this.adIndex = adIndex;
    this.categories = categories;
  }

  updated(): void {}

  cleanupEvents(): void {}

  setupEvents() {}

  requestAd(): void {
    const config = $config.get();
    const configHasPlacement = config?.placements?.find(
      (p) => p.placementId === this.placementId
    );
    if (configHasPlacement) {
      renderAd({
        containerId: this.containerId,
        placementId: this.placementId,
        initialLayoutType: this.initialLayoutType,
        categories: this.categories
      });
    }
  }

  render() {
    return html`<slot
      name="${`${this.placementId}--slot`}"
      id="${this.containerId || `${this.placementId}--container`}"
    ></slot>`;
  }
}
