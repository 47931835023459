import React from 'react';

import classNames from 'classnames';

export function Label({
  className,
  classes,
  adLabel
}: {
  classes: {
    badge: string;
    neutral: string;
  };
  adLabel: string;
  className?: string;
}) {
  return (
    <span className={`${classes.badge} ${classes.neutral} ${className}`}>
      {adLabel}
    </span>
  );
}

export function CustomLabel({
  adLabel,
  insetClassName = 'top-8 left-8'
}: {
  adLabel: string;
  insetClassName?: string;
}) {
  return (
    <span
      className={classNames(
        'absolute s-text py-4 px-8 text-12 font-normal rounded-tl-8 rounded-br-4 s-bg-subtle',
        insetClassName
      )}
    >
      {adLabel}
    </span>
  );
}
