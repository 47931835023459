import { useState, useEffect } from 'react';

import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { LayoutType } from '@schibsted-nmp/advertising-shared';

function useLayoutTypeSub(initialLayoutType: LayoutType) {
  const [layoutType, setLayoutType] = useState<LayoutType>(initialLayoutType);

  useEffect(() => {
    const unsubscribe = messageBus.subscribe(
      events.COMPANION.channel,
      events.COMPANION.LAYOUT_TYPE_CHANGE.topic,
      (event) => {
        const payload = events.COMPANION.LAYOUT_TYPE_CHANGE.getPayload(event);
        setLayoutType(payload.layoutType);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  return layoutType;
}

export default useLayoutTypeSub;
