import React, { useEffect, useRef } from 'react';

import { PlacementId, VideoAdObj } from '@schibsted-nmp/advertising-shared';
import { showTag } from '@client/xandr/xandr';

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: VideoAdObj;
};

export default function VideoAdComponent(props: Props) {
  const { targetId } = props;
  const ref = useRef<HTMLInputElement>(null);

  // No lazy loading for video ads
  useEffect(() => {
    showTag(targetId);
  }, []);

  return (
    <div
      className="mx-auto relative w-full overflow-hidden"
      ref={ref}
      id={targetId}
    />
  );
}
