import { atom } from '@podium/store';
import { PageOpts } from '@schibsted-nmp/advertising-shared';

import { mergeKeywords } from '../utils/mergeKeywords';

import { AtomChannels, AtomTopics } from './index';

export const $xandrPageOpts = atom<PageOpts>(
  AtomChannels.Podlet,
  AtomTopics.XandrPageOpts,
  {} as PageOpts
);

export const setXandrPageOpts = (pageOpts: Partial<PageOpts>) => {
  $xandrPageOpts.set({
    ...$xandrPageOpts.get(),
    ...pageOpts,
    keywords: mergeKeywords(
      $xandrPageOpts.get().keywords,
      pageOpts.keywords || {}
    )
  });
};
