import { GamKeyValue, Slot } from '@schibsted-nmp/advertising-shared';

export function setTargetingOnSlotOrGlobal({
  targeting,
  slot,
  global
}: {
  targeting: GamKeyValue[];
  slot?: Slot | null;
  global?: boolean;
}) {
  targeting?.forEach((target) => {
    if (target.key && target.value) {
      if (slot) {
        slot.setTargeting(target.key, target.value);
      } else if (global) {
        window.googletag.pubads().setTargeting(target.key, target.value);
      }
    }
  });
}
