import React from 'react';

import { useInitiateGamUnit } from '@client/adManager/AdUnit/useInitiateGamUnit';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { useIsBannerAdnamiAd } from '@client/xandr/components/banner/useBannerIsAdnami';
import {
  GamPlacement,
  isForecastingPlacement,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

type Props = {
  placement: GamPlacement;
  containerId: string;
};
function GamAdUnit({ containerId, placement }: Props) {
  const ref = React.createRef<HTMLDivElement>();
  const forecasting = isForecastingPlacement(placement);

  const hasTestCampaignFlag = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamTestCampaign
  );

  useInitiateGamUnit({
    forecasting,
    placement,
    containerId,
    ref
  });

  const isAdnami = useIsBannerAdnamiAd(ref, placement.placementId);

  if (forecasting && !hasTestCampaignFlag) {
    return (
      <div
        style={{
          opacity: 0,
          width: 1,
          height: 1,
          position: 'absolute'
        }}
        ref={ref}
        id={placement.placementId}
      />
    );
  }
  return (
    <div ref={ref} className="flex w-full h-full">
      <div
        className="mx-auto w-full h-full"
        id={placement.placementId}
        data-is-adnami={isAdnami}
      />
    </div>
  );
}

export default GamAdUnit;
