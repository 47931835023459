import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { getAdNamiScript } from '@client/core/utils/getAdnamiScript';
import {
  debugLog,
  InitialState,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { getRelevantYieldScript } from './core/relevantDigital/config';
import { getXandrRelevantYieldScript } from './xandr/relevantDigitalConfig';

const loadScript = ({
  name,
  src,
  defer,
  crossOrigin
}: {
  name: string;
  src: string;
  defer?: boolean;
  crossOrigin?: string;
}) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;

    if (defer) script.defer = defer;
    if (crossOrigin) script.crossOrigin = crossOrigin;
    script.onload = () => {
      debugLog(`${name} loaded from advertising-podlet`);
      resolve(true);
    };
    document.head.appendChild(script);
  });

interface Script {
  name: string;
  src: string;
  defer?: boolean;
  crossorigin?: string;
}

export async function loadAllScripts(
  brand,
  env,
  deviceType,
  initialState: InitialState,
  xandrSdkVersion = '0.58.0'
) {
  const enableGamPrebid = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamPrebid
  );
  const relevantYieldScript = !enableGamPrebid
    ? getXandrRelevantYieldScript(brand, deviceType)
    : getRelevantYieldScript(brand, deviceType);
  const scriptsToLoad = [
    {
      name: 'cogwheel',
      src: 'https://cogwheel.inventory.schibsted.io/prod/latest/gdpr-bundle.js',
      defer: true
    },
    getAdNamiScript(brand),
    relevantYieldScript
  ] as Script[];

  // Load stuff that makes pulse.getTracker work
  if (env === 'local') {
    scriptsToLoad.push({
      name: 'pulse',
      src: 'https://sdk-cdn.pulse.m10s.io/versioned/3.0/pulse-modern.min.js'
    });
    scriptsToLoad.push({
      name: 'pulse-init',
      src: 'https://assets.finn.no/pkg/@finn-no/finn-pulse-init/v1/finn-pulse-init.min.js'
    });
  }
  if (initialState.config.adServer.gam) {
    scriptsToLoad.push({
      name: 'gam',
      src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      defer: true
    });
  }
  if (initialState.config.adServer.adn) {
    scriptsToLoad.push({
      name: 'adn',
      src: 'https://cdn.adnuntius.com/adn.js',
      defer: true
    });
  }
  if (initialState.config.adServer.xandr) {
    scriptsToLoad.push({
      name: 'xandr-ast',
      src: `https://acdn.adnxs.com/ast/static/${xandrSdkVersion}/ast.js`,
      defer: true,
      crossorigin: 'anonymous'
    });
  }
  const loadAllScriptsPromises = scriptsToLoad.map(
    (script) => script?.src && loadScript(script)
  );
  await Promise.all(loadAllScriptsPromises);
}
