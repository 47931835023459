import { handleMiddle2OverlapsWithFilter } from '@client/core/utils/dom/handleAdsOverlap/handleMiddle2OverlapsWithFilter';
import {
  PlacementId,
  RecommendationCategories,
  updateKeywordsWithHideAdnami,
  updateKeywordsWithWidth,
  XandrPlacement
} from '@schibsted-nmp/advertising-shared';
import { $config } from '@client/core/atoms/config';

import {
  adSizesToPairs,
  getRightSkyscraperSizes,
  isAdnamiSize
} from './processAdSizes';

/**
 * This is for testing and will be moved to the Database OR Xandr eventually.
 *
 * It is required config for video ads.
 *
 * @param allowedFormats
 */
function getVideoTagConfig(allowedFormats: string[]) {
  if (allowedFormats.includes('video')) {
    return {
      video: {
        frameworks: [1, 2]
      },
      rendererOptions: {
        playerTechnology: ['html5'],
        adText: 'Mainos',
        showMute: true,
        showVolume: true,
        showProgressBar: true,
        disableTopBar: true,
        // autoInitialSize
        // This does not work when safeFrame is turned on
        // https://docs.xandr.com/bundle/seller-tag/page/seller-tag/set-up-on-page-tags-for-outstream-video.html#d9224e117
        // See components/video/index.tsx - getCustomAutoInitialSize()
        autoInitialSize: true,
        allowFullscreen: false,
        playVideoVisibleThreshold: 0,
        skippable: {
          videoThreshold: 10,
          videoOffset: 5,
          skipLocation: 'top-right',
          skipText: 'Videon voi pysäyttää %%TIME%%s kuluttua',
          skipButtonText: 'Sulje'
        }
      }
    };
  }
  return {};
}

function getCategoriesFromRecommendation(
  categories?: RecommendationCategories
) {
  const { brand, vertical } = $config.get();

  const lowerCaseBrand = brand.toLowerCase();
  const lowerCaseVertical = vertical?.toLowerCase();
  const prefix = `no-sno-${lowerCaseBrand}-${lowerCaseVertical}`;
  return categories
    ? {
        [`${prefix}-main_category`]: [categories.main_category],
        [`${prefix}-sub_category`]: [categories.sub_category]
      }
    : {};
}

export function getTagConfig(
  placementId: PlacementId,
  targetId: string,
  categories?: RecommendationCategories
) {
  const { brand, placements } = $config.get();
  const placement = placements?.find(
    (p) => p.placementId === placementId
  ) as XandrPlacement;
  const safeSizes = placement?.adServer.config.sizes || [];
  const safeKeywords = placement?.adServer?.config?.keywords || {};

  if (!placement?.adServer.config.inventoryCode || safeSizes.length === 0) {
    return null;
  }

  let sizes = safeSizes.sort((a, b) => a.height - b.height);
  let keywords = {
    ...getCategoriesFromRecommendation(categories),
    ...safeKeywords
  };

  // Hide adnami middle 2 if the state is set from handleAdnamiOverlap.ts
  if (placementId === 'advt_middle_2' && handleMiddle2OverlapsWithFilter()) {
    sizes = sizes.filter((size) => !isAdnamiSize(size));
    keywords = updateKeywordsWithHideAdnami(keywords);
  }

  if (placementId === 'advt_right_1') {
    sizes = getRightSkyscraperSizes(sizes);
    keywords = updateKeywordsWithWidth(keywords, sizes);
  }
  let tagConfig = {
    targetId,
    sizes: adSizesToPairs(sizes),
    keywords,
    invCode: placement?.adServer.config.inventoryCode,
    allowedFormats: placement?.adServer.config.allowedFormats,
    native: placement?.adServer.config.native
  };

  if (brand === 'tori') {
    tagConfig = {
      ...tagConfig,
      ...getVideoTagConfig(placement?.adServer.config.allowedFormats)
    };
  }
  return tagConfig;
}
