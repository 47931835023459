import { debounce } from 'throttle-debounce';

import { debugLog, PlacementId } from '@schibsted-nmp/advertising-shared';

import { loadTagsWithRelevantDigitalPrebid } from './relevantDigitalPrebid';

interface CallbackType {
  (arg?: any): void;
}

export function pushToAnqSafe(callback: CallbackType): void {
  if (window.apntag && window.apntag.anq) {
    window.apntag.anq.push(() => {
      if (window.apntag) {
        callback();
      }
    });
  }
}

export function setPageOpts(pageOpts: any, callback?: CallbackType): void {
  if (window?.apntag?.setPageOpts && pageOpts) {
    pushToAnqSafe(() => {
      window.apntag.setPageOpts(pageOpts);
      callback?.();
    });
  }
}

// How rare Xandr /v3 requests are done to the https://acdn.adnxs.com/ast/ast.js library
const XANDR_REQUEST_DEBOUNCE = 200;

const debouncedLoadTagsFunction = debounce(
  XANDR_REQUEST_DEBOUNCE,
  () => {
    loadAstTags();
  },
  { atBegin: false }
);

export function debouncedLoadAstTags() {
  debouncedLoadTagsFunction();
}

// The following functions remain unchanged
export function loadAstTags() {
  pushToAnqSafe(() => {
    loadTagsWithRelevantDigitalPrebid();
  });
}

export function getDebouncedLoadTagsFunction() {
  return debouncedLoadAstTags;
}

export const horseshoePlacements = [
  PlacementId.Wallpaper,
  PlacementId.Left1,
  PlacementId.Right1,
  PlacementId.Top1
];

export function defineTag(tag: any): void {
  pushToAnqSafe(() => {
    if (tag && window.apntag) {
      debugLog(`defineTag `, tag);
      window.apntag.defineTag(tag);
    }
  });
}

export function showTag(id: string): void {
  pushToAnqSafe(() => {
    debugLog(`showTag ${id} `);
    window.apntag.showTag(id);
  });
}

function setupEvent(event: string, id: string, callback?: CallbackType): void {
  pushToAnqSafe(() => {
    debugLog(`SETUP ${event} for id: ${id}`);

    window.apntag.onEvent(event, id, (adObj) => {
      callback?.(adObj);
    });
  });
}

export enum XandrLifecycleEvents {
  AD_REQUESTED = 'adRequested',
  AD_AVAILABLE = 'adAvailable',
  AD_LOADED = 'adLoaded',
  AD_LOADED_MEDIATED = 'adLoadedMediated',
  AD_NO_BID = 'adNoBid',
  AD_NO_BID_MEDIATED = 'adNoBidMediated',
  AD_BAD_REQUEST = 'adBadRequest'
}

export function onAdRequested(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_REQUESTED, id, callback);
}

export function onAdAvailable(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_AVAILABLE, id, callback);
}

export function onAdLoaded(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_LOADED, id, callback);
}

export function onAdLoadedMediated(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_LOADED_MEDIATED, id, callback);
}

export function onAdNoBid(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_NO_BID, id, callback);
}

export function onAdNoBidMediated(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_NO_BID_MEDIATED, id, callback);
}

export function onAdBadRequest(id: string, callback?: CallbackType): void {
  setupEvent(XandrLifecycleEvents.AD_BAD_REQUEST, id, callback);
}
