import React from 'react';

import classNames from 'classnames';

export const TabButton = ({
  label,
  active,
  onClick
}: {
  label: string;
  active: boolean;
  onClick: () => void;
}) => (
  <button
    className={classNames(
      'p-4 text-s font-medium rounded-md transition-colors',
      {
        's-bg-positive-subtle s-border-b-positive border-b-2': active,
        'bg-gray-50 text-gray-600 hover:bg-gray-100 hover:text-gray-800':
          !active
      }
    )}
    onClick={onClick}
  >
    {label}
  </button>
);
