import {
  getInitialState,
  hasInitialPlacements
} from '@client/core/utils/dom/getInitialState';
import { debugLog, InitialState } from '@schibsted-nmp/advertising-shared';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';

import { loadAndActivateI18n } from '../i18n';
import { initiateAdvt } from './initiate';
import { loadAllScripts } from './loadAllScripts';
import { setInitialGlobalAtoms } from './core/atoms';

(() => {
  try {
    const initialState = getInitialState();
    if (!initialState) return;
    setupGlobals(initialState);
    logForLocalEnvironment(initialState);
    const hasPlacements = hasInitialPlacements(initialState);
    if (!hasPlacements) return;

    loadAndActivateI18n(initialState.locale);

    const { brand, env, deviceType, xandrSdkVersion } =
      getLoadScriptParams(initialState);

    loadAllScripts(
      brand.toLowerCase(), // TODO: Remove toLowerCase when config provides lowercase brand
      env,
      deviceType.toLowerCase(), // TODO: Remove toLowerCase when config provides lowercase deviceType
      initialState,
      xandrSdkVersion
    )
      .then(() => initiateAdvt(initialState))
      .catch((e) => debugLog('Failed to load scripts', e));
  } catch (e) {
    sendErrorMessageMetricToServer(e.message);
    debugLog('Something went wrong while loading advertising-podlet', e);
  }
})();

function logForLocalEnvironment(initialState: InitialState) {
  const { config, env } = initialState;
  if (env !== 'local') return;
  console.log({ initialState });
  if (!config?.placements?.length)
    console.error('NB! No advertising placements returned from server');
}

function getLoadScriptParams(initialState: InitialState) {
  const { config, env } = initialState;
  const { adServer, brand, deviceType } = config;
  const xandrSdkVersion =
    adServer.xandr?.pageOpts?.keywords?.['nmp-xandr_version']?.[0] || '0.58.0';
  return { brand, env, deviceType, xandrSdkVersion };
}

/**
 * Sets up some globals to manage ad config and related utilities.
 */
function setupGlobals(initialState: InitialState): void {
  window.apntag = window.apntag || {};
  window.apntag.anq = window.apntag.anq || [];

  setInitialGlobalAtoms(initialState);
}
