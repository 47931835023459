import { AdConfigPlacement, AdServer } from './types/api/adConfig';
import { AfsPlacement } from './types/adServers/afs';
import { GamPlacement } from './types/adServers/gam';
import { AdnPlacement } from './types/adServers/adn';
import { XandrPlacement } from './types/xandr';

export function filterAfsPlacements(
  placements: AdConfigPlacement[]
): AfsPlacement[] {
  return placements.filter(
    (placement) => placement.adServer.type === AdServer.AFS
  ) as AfsPlacement[];
}

export function filterGamPlacements(
  placements: AdConfigPlacement[]
): GamPlacement[] {
  return placements.filter(
    (placement) => placement.adServer.type === AdServer.GAM
  ) as GamPlacement[];
}

export function filterAdnPlacements(
  placements: AdConfigPlacement[]
): AdnPlacement[] {
  return placements.filter(
    (placement) => placement.adServer.type === AdServer.Adn
  ) as AdnPlacement[];
}

export function filterXandrPlacements(
  placements: AdConfigPlacement[]
): XandrPlacement[] {
  return placements.filter(
    (placement) => placement.adServer.type === AdServer.Xandr
  ) as XandrPlacement[];
}
