import * as React from 'react';
import { useEffect } from 'react';

import { PlacementId } from '@schibsted-nmp/advertising-shared';

function callbackToInitWallpaper(event: MessageEvent) {
  const safeOrigins = [
    'https://acdn.adnxs-simple.com',
    'https://acdn.adnxs.com'
  ];
  if (!safeOrigins.includes(event.origin)) return;

  const wpMsgPrefix = 'wallpaper:url:';
  const msg = `${event.data}`;
  if (msg.indexOf(wpMsgPrefix) !== -1) {
    const wpUrl = msg.substring(wpMsgPrefix.length, msg.length);
    const { body } = document;
    if (body) {
      body.style.backgroundColor = 'transparent !important';
      body.style.backgroundImage = `url("${wpUrl}")`;
      body.style.backgroundRepeat = 'no-repeat';
      body.style.backgroundPosition = 'center 66px';
      body.style.backgroundAttachment = 'fixed';
    }
  }
}

type Props = {
  placementId: PlacementId;
  targetId: string;
};

export function WallpaperAdComponent(props: Props) {
  const { targetId } = props;

  useEffect(() => {
    if (window !== undefined) {
      const { apntag } = window;

      if (apntag && apntag.anq) {
        apntag.anq.push(() => {
          apntag.anq.push(() => apntag.showTag(targetId));
        });
      }
      window.addEventListener('message', callbackToInitWallpaper, false);
      const { body } = document;
      if (body) {
        body.classList.add('wallpaper');
      }
    }
    return function cleanup() {
      if (window !== undefined) {
        window.removeEventListener('message', callbackToInitWallpaper);
        const { body } = document;
        if (body) {
          body.style.removeProperty('background-color');
          body.style.removeProperty('background-image');
          body.style.removeProperty('background-repeat');
          body.style.removeProperty('background-position');
          body.style.removeProperty('background-attachment');
          body.classList.remove('wallpaper');
        }
      }
    };
  }, [targetId]);

  return <div id={targetId} className="hidden" />;
}
