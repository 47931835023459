import { setAdvtState } from './safeSetAdvtState';

const ENABLED_PARAM = 'advt_debug';
let isDebugMode: boolean | undefined;

export function hasQueryParam(param: string) {
  return new URL(window.location.href).searchParams.get(param) !== null;
}

function initializeIsDebugMode() {
  const localStorageHasDebugFlag =
    window.localStorage.getItem(ENABLED_PARAM) === 'true';
  return localStorageHasDebugFlag || hasQueryParam(ENABLED_PARAM);
}

setAdvtState('toggleDebug', () => {
  const localStorageHasDebugFlag =
    window.localStorage.getItem(ENABLED_PARAM) === 'true';

  if (localStorageHasDebugFlag) {
    window.localStorage.removeItem(ENABLED_PARAM);
    return false;
  }
  window.localStorage.setItem(ENABLED_PARAM, 'true');
  return true;
});

export function isDebug() {
  if (isDebugMode === undefined) {
    isDebugMode = initializeIsDebugMode();
  }
  return isDebugMode;
}

const config = {
  name: 'advertising-podlet',
  primaryColor: 'skyblue',
  secondaryColor: 'royalblue'
};

export function debugLog(...args: any[]) {
  if (!isDebug()) return;

  console.log(
    `%c ${config.name}`,
    `color:${config.primaryColor}; background:${config.secondaryColor}`,
    ...args
  );
}
