import React from 'react';

import { createRoot } from 'react-dom/client';

import { updateMetricByKey } from '@client/core/atoms/metrics';
import { $placementsMap } from '@client/core/atoms/placements';
import {
  GamPlacement,
  PlacementId,
  PlacementStatusesEnum
} from '@schibsted-nmp/advertising-shared';

import GamAdUnit from './GamAdUnit';

export function renderGamAd(props: {
  containerId: string;
  placementId: PlacementId;
  initialLayoutType?: string;
}) {
  const element = document.getElementById(props.containerId);
  const placementElement = element && createRoot(element);

  if (placementElement) {
    const placements = $placementsMap.get();
    if (Object.keys(placements).length === 0) {
      console.error(`No placements found`);
      updateMetricByKey(props.placementId, PlacementStatusesEnum.NotInConfig);
      return;
    }
    const placement = placements[props.placementId];
    if (!placement) {
      console.error(`PlacementId: ${props.placementId} not found`);
      return;
    }
    placementElement.render(
      <GamAdUnit
        containerId={props.containerId}
        placement={placement as GamPlacement}
      />
    );
  }
}
