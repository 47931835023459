import { Keywords } from '@schibsted-nmp/advertising-shared';

export function mergeKeywords(
  originalKeywords: Keywords = {},
  newKeywords: Keywords = {}
): Keywords {
  const mergedKeywords = { ...originalKeywords };

  for (const key in newKeywords) {
    if (key in mergedKeywords) {
      // If key exists already, make an array of unique values
      mergedKeywords[key] = Array.from(
        new Set([...mergedKeywords[key], ...newKeywords[key]])
      );
    } else {
      // If key doesn't exist, just assign the newKeywords value
      mergedKeywords[key] = newKeywords[key];
    }
  }

  return mergedKeywords;
}
