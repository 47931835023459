import { debugLog, PlacementId } from '@schibsted-nmp/advertising-shared';
import { createIntersectionObserver } from '@client/core/utils/createIntersectionObserver';
import { batchRequestAd } from '@client/core/batchRequesting/batchRequestState';

export function renderDisplayAd(props: {
  containerId: string;
  placementId: PlacementId;
  initialLayoutType?: string;
}) {
  const { placementId, containerId } = props;
  const containerElement = document.getElementById(containerId);

  if (containerElement) {
    const childDiv = document.createElement('div');
    childDiv.id = String(placementId);

    childDiv.style.maxWidth = '100vw';
    childDiv.style.overflow = 'hidden';
    containerElement.appendChild(childDiv);

    const iframeContainerObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (const node of mutation.addedNodes) {
            if (node instanceof HTMLIFrameElement) {
              // Adjust iframe styles if needed
              node.style.position = 'absolute';
              node.style.transform = 'translate(-50%, -50%)';
              node.style.top = '50%';
              node.style.left = '50%';
              iframeContainerObserver.disconnect(); // Optional: stop observing after iframe is added
              break;
            }
          }
        }
      }
    });

    iframeContainerObserver.observe(childDiv, { childList: true });

    const onIntersection = () => {
      debugLog(placementId, ' Interstected');
      batchRequestAd(placementId);
      if (observer) {
        observer.disconnect();
      }
    };

    const observer = createIntersectionObserver(
      childDiv,
      placementId,
      onIntersection
    );
  }
}
