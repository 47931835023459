import React, { useEffect, useRef, useState } from 'react';

import {
  BannerAdObj,
  debugLog,
  isAdnamiAd
} from '@schibsted-nmp/advertising-shared';
import { publishAdnamiPlacement } from '@client/core/utils/dom/handleAdsOverlap/handleStickySkyscrapers';

export function useIsBannerAdnamiAd(
  ref: React.RefObject<HTMLDivElement>,
  targetId: string,
  adObj?: BannerAdObj
) {
  const [isAdnami, setIsAdnami] = useState(false);
  const observerRef = useRef<MutationObserver | null>(null);

  useEffect(() => {
    if (adObj && isAdnamiAd(adObj)) {
      setIsAdnami(true);
      return () => {
        observerRef.current?.disconnect();
      };
    }
    const container = ref.current;

    const isNotSupported = typeof MutationObserver === 'undefined';
    // Combine the conditions and use a single return statement for cleanup.
    if (!container || isNotSupported || isAdnami) {
      return () => {
        observerRef.current?.disconnect();
      };
    }

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        // Check if there are added nodes in this mutation
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          // Look for an iframe element among the added nodes
          for (const node of mutation.addedNodes) {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const element = node as Element;
              const iframe =
                element.tagName === 'IFRAME'
                  ? element
                  : element.querySelector('iframe');
              if (iframe) {
                checkAdnami(iframe as HTMLIFrameElement, container);
                break; // Assuming you only need to find one iframe and act on it
              }
            }
          }
        }
      }
    });

    observerRef.current = observer;
    observer.observe(container, { childList: true, subtree: true });

    // Cleanup function
    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref.current, targetId, adObj, isAdnami]); // Add isAdnami to the dependency array

  useEffect(() => {
    if (isAdnami) {
      publishAdnamiPlacement(targetId);
    }
    observerRef.current?.disconnect();
  }, [isAdnami, targetId]); // Effect specifically for handling 'isAdnami' state change

  function checkAdnami(iframe: HTMLIFrameElement, container: HTMLDivElement) {
    const containerHTML = container.innerHTML;
    const adnamiClassName =
      containerHTML.includes('adnm') || containerHTML.includes('adnami');

    if (adnamiClassName) {
      debugLog(`Set adnami ad because of adnami class name in container`);
      setIsAdnami(true);
    } else {
      const MARGIN = 50;
      const iframeRect = iframe.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const isFullWidth =
        iframeRect.left <= MARGIN && iframeRect.right >= viewportWidth - MARGIN;
      if (isFullWidth) {
        debugLog(`Set adnami ad because of full width iframe`);
        setIsAdnami(true);
      }
    }
  }

  return isAdnami;
}
