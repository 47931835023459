import { PlacementId } from '@schibsted-nmp/advertising-shared';
import { atom } from '@podium/store';

export enum AtomChannels {
  Podlet = 'advertising-podlet'
}
export enum AtomTopics {
  BatchRequestAdQue = 'batchRequestAdQueue',
  RequestedPlacements = 'requestedPlacements'
}

export const $batchRequestAdQueue = atom<PlacementId[]>(
  AtomChannels.Podlet,
  AtomTopics.BatchRequestAdQue,
  []
);

export const $requestedPlacements = atom<PlacementId[]>(
  AtomChannels.Podlet,
  AtomTopics.RequestedPlacements,
  []
);

export const batchRequestAd = (placementId: PlacementId) =>
  !$requestedPlacements.value?.includes(placementId) &&
  $batchRequestAdQueue.set([
    ...($batchRequestAdQueue.value ?? []),
    placementId
  ]);
