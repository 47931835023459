import * as React from 'react';
import { useMemo, useRef } from 'react';

import { asPx, textWithEllipse } from '@client/core/utils/string';
import { useLoadNativeAd } from '@client/core/hooks';
import Comment from '@client/xandr/components/native/parts/Comment';

import { NativeAdProps } from './NativeAdComponent';
import NativeCogwheel from './parts/NativeCogwheel';

/**
 * Premium ads served from Xandr (GAM later) rendering a similar native ad layout as the Sponsored Content ads.
 * They get their script initialised by the content-management-podlet, if that one is not already rendered as a podlet on the page.
 */
export default function NativePremiumCpmComponent({
  adObj,
  placementId,
  targetId
}: NativeAdProps) {
  const ref = useRef(null);
  useLoadNativeAd(adObj, targetId, placementId, ref);

  const { native } = adObj;

  // Found here: https://github.schibsted.io/nmp-web/content-management-podlet/blob/main/src/types/types-cmp.ts
  const cmpContent = useMemo(
    () => ({
      id: `${targetId}-cpm`, // Mapping targetId as id
      kicker: textWithEllipse(native.title), // Reversed on web to make mobile work fine too
      title: textWithEllipse(native.body), // Reversed on web to make mobile work fine too
      advertiserName: native.sponsoredBy, // Mapping native.sponsoredBy to advertiserName
      url: native.clickUrl, // Mapping native.clickUrl to url
      // kicker: native.kicker, // Optional kicker mapping if exists
      // tagline: native.tagline, // Optional tagline mapping if exists
      ctaButtonText: native.cta,
      advertiserIconImage: {
        url: native?.icon?.url || native?.iconImgUrl || '', // Fallback for icon image url
        id: '', // Icon id can be assigned accordingly if needed
        height: asPx(native?.icon?.height), // Converting height to pixels
        width: asPx(native?.icon?.width) // Converting width to pixels
      },
      image: {
        url: native?.image?.url || '', // Fallback for image url
        id: '', // Image id can be assigned accordingly if needed
        height: native?.image?.height, // Keeping image height as is
        width: native?.image?.width // Keeping image width as is
      }
    }),
    [native, targetId]
  );

  // Defined here: https://github.schibsted.io/nmp-web/content-management-podlet/blob/main/src/client/contentfulBlocks/containers/sponsored-content-cmp-wrapper.ts
  return (
    <div ref={ref as any} id={targetId} className="col-span-full">
      <sponsored-content-cmp-wrapper cmpContent={JSON.stringify(cmpContent)}>
        <NativeCogwheel adObj={adObj} />
      </sponsored-content-cmp-wrapper>
      <Comment placement="NativePremiumCpmComponent" adObj={adObj} />
    </div>
  );
}
