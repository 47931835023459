import { TemplateResult } from 'lit';

import {
  AdServer,
  LayoutType,
  PlacementId,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';

/*  
 AdVendor is an abstract class that represents an ad vendor
 It has a setupEvents method that sets up event listeners
 It has a cleanupEvents method that removes event listeners
 It has a requestAd method that requests an ad
 It has a render method that returns a TemplateResult
 Specific ad vendors extend this class and implement these methods
  */
export abstract class AdVendor {
  adServer: AdServer;

  containerId: string;

  placementId: PlacementId;

  adIndex?: number;

  categories?: RecommendationCategories;

  initialLayoutType?: LayoutType;

  className: string;

  // Use the interface in the abstract method
  abstract setupEvents(): void;

  abstract cleanupEvents(): void;

  abstract requestAd(): void;

  abstract updated(
    changedProperties: Map<string | number | symbol, unknown>
  ): void;

  abstract render(): TemplateResult;

  setupBatchRequesting?(): void;
}
