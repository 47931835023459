import type { SizeMediaType } from './types/adServers/gam';
import { GamSize } from './types/adServers/gam';

export function getSizesByMediaType(mediaTypes: SizeMediaType[]): GamSize[] {
  const sizes: GamSize[] = [];

  mediaTypes.forEach((size) => {
    if (size.type === 'display' || size.type === 'video') {
      sizes.push([size.width, size.height]);
    } else if (size.type === 'native') {
      sizes.push('fluid');
    }
  });
  return sizes;
}
