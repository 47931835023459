import { PlacementId } from '@schibsted-nmp/advertising-shared';

export function getInScreenData(placementId: PlacementId): {
  threshold: number;
  rootMargin: string;
} {
  switch (placementId) {
    case PlacementId.Right1:
      return {
        threshold: 0,
        rootMargin: '0px 0px 0px 0px'
      };
    case PlacementId.Top1:
      return {
        threshold: 0,
        rootMargin: '200px 0px 0px 0px'
      };
    default:
      return {
        threshold: 0,
        rootMargin: '100px 0px 0px 0px'
      };
  }
}

export function createIntersectionObserver(
  element: Element | null,
  placementId: PlacementId,
  callback: () => void
) {
  if ('IntersectionObserver' in window) {
    const { threshold, rootMargin } = getInScreenData(placementId);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback();
          }
        });
      },
      {
        root: null,
        rootMargin,
        threshold
      }
    );

    if (element) {
      observer.observe(element);
    } else {
      callback();
    }

    return observer;
  }
  return null;
}
