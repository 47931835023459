// https://github.schibsted.io/finn/displayads-feed
import { initialize } from '@finn-no/feed';
import { Feed } from '@schibsted-nmp/advertising-shared';

/**
 * Feed is a feature used in FINN with Xandr to display specific parameters within
 * an advertisement. It will be removed (done in a different way) when Xandr is
 * replaced by Google Ad Manager.
 *
 * We need this to support some specific pages such as Mobility Item Page in FINN.
 *
 * Feed is ran in FINN as a separate script that is loaded on all FINN pages.
 *
 * See this PR: https://github.schibsted.io/finn/advertising-podlet/pull/394
 */
export function initFeed(feed: Feed) {
  initialize();
  updateFeed(feed);
}

export function updateFeed(feed: Feed) {
  const nrmFeedString = nrmFeedToString(feed);

  // @ts-expect-error FINN is not going to be a globally defined type.
  window.FINN = window.FINN || {};
  // @ts-expect-error FINN is not going to be a globally defined type.
  window.FINN.banner = window.FINN.banner || {};
  // @ts-expect-error FINN is not going to be a globally defined type.
  window.FINN.banner.feed = feed;
  // @ts-expect-error FINN is not going to be a globally defined type.
  window.FINN.banner.feedString = nrmFeedString;
}

function nrmFeedToString(feed: Feed) {
  return feed
    ? Object.entries(feed).reduce((accumulator, currentValue) => {
        accumulator += `${currentValue[0]}=${currentValue[1]};`;
        return accumulator;
      }, '')
    : '';
}
