import { atom, deepMap } from '@podium/store';
import {
  AdConfigIntermingle,
  AdStatus,
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

import { AtomChannels, AtomTopics } from './index';

export const $placementsMap = deepMap<Record<string, ClientAdPlacement>>(
  AtomChannels.Podlet,
  AtomTopics.PlacementsMap,
  {}
);

export const $adStatusSequence = atom<Record<PlacementId, AdStatus[]>>(
  AtomChannels.Podlet,
  AtomTopics.AdStatusSequence,
  {} as Record<PlacementId, AdStatus[]>
);

export function updatePlacementKeyValueById(
  placementId: PlacementId,
  updateKey: keyof ClientAdPlacement,
  updateValue: any
) {
  $placementsMap.setKey(`${placementId}.${updateKey}`, updateValue);

  if (updateKey === 'status') {
    addToAdStatusSequence(placementId, updateValue as AdStatus);
  }
}
export function addToAdStatusSequence(
  placementId: PlacementId,
  status: AdStatus
) {
  const currentSequence = $adStatusSequence.get();
  const statusSequence = currentSequence[placementId] || [];
  $adStatusSequence.set({
    ...currentSequence,
    [placementId]: [...statusSequence, status as AdStatus]
  });
}

export function getPlacementList(): ClientAdPlacement[] {
  return Object.values($placementsMap.get())?.filter(
    (placement) => placement?.placementId
  );
}

export function getPlacementById(
  placementId: PlacementId
): ClientAdPlacement | undefined {
  return $placementsMap.get()[placementId];
}

const setPlacementToPending = (placement) => {
  updatePlacementKeyValueById(placement.placementId, 'status', 'pending');
};

export function resetRefreshPlacements() {
  getRefreshPlacements().forEach(setPlacementToPending);
}

export function setAllPlacementsToPending() {
  getPlacementList().forEach(setPlacementToPending);
}

export function getRefreshPlacements() {
  return getPlacementList().filter(
    (placement) => placement.status === 'refresh'
  );
}

export function getInterminglePlacements() {
  return getPlacementList().filter((placement) => {
    if (placement.placementId?.includes('forecasting')) return false;
    const intermingle = placement.intermingle as AdConfigIntermingle;
    if (!intermingle) return false;

    // Recirc ads are not loaded as intermingle placements anymore. Instead, they are set up by the recommendation API
    // and rendered from the components in advertising-companion (AdvertisingWebComponent).
    const isRecircAd =
      placement.placementId.includes('recirc') ||
      placement.placementId.includes('ad-stream');
    if (isRecircAd) return false;
    return Boolean(intermingle.list && intermingle.grid);
  });
}
