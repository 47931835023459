import { html } from 'lit';

import { AdVendor } from '@client/core/AdVendor';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

export class EmptyAdVendor extends AdVendor {
  constructor(placementId: PlacementId) {
    super();
    this.placementId = placementId;
    this.containerId = `${placementId}--container`;
  }

  setupEvents() {}

  cleanupEvents() {}

  requestAd() {}

  updated() {}

  render() {
    return html``;
  }
}
