import { atom } from '@podium/store';

import { AtomChannels, AtomTopics } from './index';

export const $cycleAtom = atom<number>(
  AtomChannels.Podlet,
  AtomTopics.Cycle,
  0
);

export const incrementCycleToRefreshBanners = () => {
  $cycleAtom.set($cycleAtom.get() + 1);
};
