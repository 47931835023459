export const fetchElement = (query: string): HTMLElement | null =>
  document.querySelector(query);

export const fetchElementById = (id: string) => document.getElementById(id);

export const fetchAllElements = (query: string): NodeListOf<HTMLElement> =>
  document.querySelectorAll(query);

export const querySelect = (query: string): HTMLElement | null =>
  document.querySelector(query);

export const getDocumentHeight = () =>
  Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  );

type CSSProperties = Partial<CSSStyleDeclaration>;

export function styleElements<T extends keyof CSSProperties>(
  elements: NodeListOf<HTMLElement>,
  styleObject: { [P in T]: CSSProperties[P] }
): void {
  elements.forEach((element) => {
    Object.entries(styleObject).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        (element.style as any)[key] = value;
      }
    });
  });
}
