/**
 * Converts all values in an object to arrays. If a value is already an array, it remains unchanged.
 * @example
 * const input = { key: "value", nums: 123, existing: ["already", "array"] };
 * const result = objectValuesToArrays(input);
 * // Result: { key: ["value"], nums: [123], existing: ["already", "array"] }
 */
export function objectValuesToArrays<T extends Record<string, any>>(
  obj: T
): { [K in keyof T]: T[K][] } {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      Array.isArray(value) ? value : [value]
    ])
  ) as { [K in keyof T]: T[K][] };
}
