import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { $placementMetricSequence } from '@client/core/atoms/metrics';
import { $adStatusSequence } from '@client/core/atoms/placements';
import {
  ClientAdPlacement,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

export function PlacementItem({ placement }: { placement: ClientAdPlacement }) {
  const { placementId } = placement;
  const [adStatusSequence, setAdStatusSequence] = useState<string[]>([]);
  const [metricStatusSequence, setMetricStatusSequence] = useState<string[]>(
    []
  );

  useEffect(() => {
    const unsubscribe = $adStatusSequence.subscribe(() => {
      setAdStatusSequence($adStatusSequence.get()[placementId] || []);
    });
    return unsubscribe;
  }, [placementId]);

  useEffect(() => {
    const unsubscribe = $placementMetricSequence.subscribe(() => {
      setMetricStatusSequence(
        $placementMetricSequence.get()[placementId] || []
      );
    });
    return () => unsubscribe();
  }, [placementId]);

  const getIntermingleInfo = () => {
    if (placement?.intermingle?.grid && placement?.intermingle?.list) {
      return (
        <div>
          <p className="text-s">
            <b>Intermingle:</b> Grid: {placement.intermingle.grid}, List:{' '}
            {placement.intermingle.list}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <li
      onClick={() => showPlacement(placementId)}
      className={classNames('cursor-pointer border p-4 rounded-4', {
        's-border-positive s-bg-positive-subtle':
          placement?.status === 'loaded',
        's-border-negative s-bg-negative-subtle': placement?.status === 'error'
      })}
    >
      <h4>{placementId}</h4>

      {placement && (
        <>
          <p className="text-s">
            <b>Ad Server:</b> {placement.adServer.type}
          </p>
          {getIntermingleInfo()}
          <p className="text-s">
            <b>AdType:</b> {placement.creativeType}
          </p>
          <p className="text-s">
            <b>Status:</b> {placement.status}
          </p>
        </>
      )}
      <b className="text-s">Metrics sequence:</b>

      <div className="flex flex-row flex-wrap gap-4">
        {metricStatusSequence.map((status, index) => (
          <StatusItem
            key={index + status}
            status={status}
            index={index}
            hasBackground={status === 'refresh'}
          />
        ))}
      </div>

      <b className="text-s">Ad Status sequence:</b>
      <div className="flex flex-row flex-wrap gap-4">
        {adStatusSequence.map((status, index) => (
          <StatusItem
            key={index + status}
            status={status}
            index={index}
            hasBackground={status === 'pending'}
          />
        ))}
      </div>
    </li>
  );
}

const showPlacement = (placementId: PlacementId) => {
  const el = document.getElementById(placementId);
  if (el) {
    console.log(`Found ${placementId}. Scrolling to it!`);
    el.scrollIntoView({ behavior: 'smooth' });
  }
};

interface StatusItemProps {
  status: string;
  index: number;
  hasBackground: boolean;
}
const StatusItem = ({ status, hasBackground }: StatusItemProps) => (
  <div
    title={hasBackground ? 'Filter was updated here' : status}
    className={classNames('p-2 text-s rounded-4 border', {
      's-bg-warning-subtle': hasBackground
    })}
    key={status}
  >
    {status}
  </div>
);
