import { Keywords } from './types/xandr/pageOpts';
import { Size } from './types/xandr';

export const getKeywordByKey = (keywords: Keywords, key: string) => {
  if (keywords) {
    return keywords[key];
  }
  return undefined;
};

/**
 * For right_1 placement, we need to set a definition on which widths the ad supports
 */
export const updateKeywordsWithWidth = (keywords: Keywords, sizes: Size[]) => {
  function removeSizeIfThreeByThree(size: Size) {
    // callback that checks if both height and width is 3, to be used by a .filter func
    return size.width !== 3 && size.height !== 3;
  }
  function formatToWidthString(size: Size) {
    return `${size.width}`;
  }
  keywords['nmp-right_1_required_width'] = sizes
    .filter(removeSizeIfThreeByThree)
    .map(formatToWidthString);
  return keywords;
};

/**
 * For middle_2 placement, we need to set a definition that states we're hiding adnami placement
 */
export const updateKeywordsWithHideAdnami = (keywords: Keywords) => ({
  ...keywords,
  'nmp-filter_blocker': ['block_midscroll']
});
