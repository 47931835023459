import Glimr from 'glimr-sdk';

import { Brand, debugLog, Keywords } from '@schibsted-nmp/advertising-shared';
import localStorage from '@client/core/utils/localStorage';
import { getConsentOrSubscribe } from '@client/core/utils/consent';

import { $glimrTagsAtom } from '../atoms/config';

export const GLIMR_TAG_STORAGE_KEY = 'advertising-podlet.glimrTags';

function getClientIdByBrand(brand: Brand) {
  const upperCaseBrand = brand?.toUpperCase();
  switch (upperCaseBrand) {
    case 'BLOCKET':
      return 'AM3UZB2HBZHD9R7M55JS';
    case 'TORI':
      return 'CQSKY6V5814SQJ61XFJQ';
    case 'FINN':
      return 'ESA0AB9PATGZ2AGWICEX';
    case 'DBA':
      return 'UYSCXA35QWYC4D4CWBC1';
    default:
      return '';
  }
}

export function clearPersistedGlimrTags() {
  $glimrTagsAtom.set([]);
  localStorage.clearValue(GLIMR_TAG_STORAGE_KEY);
}

export function getPersistedGlimrTags() {
  return localStorage.getItem(GLIMR_TAG_STORAGE_KEY) || [];
}

export function setPersistedGlimrTags(tags: string[]) {
  $glimrTagsAtom.set(tags);
  localStorage.setItem(GLIMR_TAG_STORAGE_KEY, tags);
}

export async function loadGlimrIfPermission(brand: Brand) {
  getConsentOrSubscribe(async (permissionValue) => {
    if (permissionValue === '1') {
      const glimrTags = await loadGlimr({ brand });
      if (glimrTags?.tags) {
        $glimrTagsAtom.set(glimrTags.tags);
        setPersistedGlimrTags(glimrTags.tags);
      }
    } else {
      clearPersistedGlimrTags();
    }
  });
}

export function getGlimrKeywords(): Keywords {
  const geoTags = $glimrTagsAtom.get();
  if (!geoTags) {
    return {};
  }
  return {
    'no-sno-geo': geoTags,
    'no-sno-weather': geoTags
  };
}

export async function loadGlimr({ brand }: { brand: Brand }): Promise<{
  tags: string[];
  tagMappings: Record<string, unknown>;
}> {
  debugLog('Load glimr');
  return new Promise<{ tags; tagMappings }>((resolve) => {
    const clientId = getClientIdByBrand(brand);
    if (!clientId) {
      resolve({ tags: [], tagMappings: {} });
    } else {
      Glimr.setTagCacheTimeInSeconds(300);
      Glimr.getTags(clientId, (tags, tagMappings) =>
        resolve({ tags, tagMappings })
      );
    }
  });
}
