import {
  ConsentStatus,
  PermissionValue
} from '@schibsted-nmp/advertising-shared';

export function subscribeToConsent(
  callback: (permissionValue: PermissionValue) => void
) {
  window?._tcf_?.isConsentedToAll((consentValue) => {
    callback(consentValue ? '1' : '0');
  });
}

export function getConsent(): boolean | null {
  return window?._tcf_?.getConsentedToAllSync();
}

export function getPermissionValueIfExists(): ConsentStatus {
  const consentValueSync = getConsent();
  return consentValueSync ? ConsentStatus.Accepted : ConsentStatus.Accepted;
}

export function getConsentOrSubscribe(
  onConsentReady: (permissionValue: PermissionValue) => void
) {
  const consentValueSync = getConsent();
  const consentNotSetYet = consentValueSync === null;

  if (consentNotSetYet) {
    let cancelled = false;

    // Fallback timeout after 2 seconds
    const timer = setTimeout(() => {
      cancelled = true;
      onConsentReady('0');
    }, 2000);

    // Subscribe to actual consent changes
    subscribeToConsent((permissionValue) => {
      if (!cancelled) {
        clearTimeout(timer); // Clear the timeout if consent arrives in time
        onConsentReady(permissionValue);
      }
    });
  } else {
    // Immediate response if consent is already set
    onConsentReady(consentValueSync ? '1' : '0');
  }
}
