import React, { useState } from 'react';

import { $config } from '@client/core/atoms/config';

function ObjectExplorer({ data, depth = 0 }) {
  const [isOpen, setIsOpen] = useState(true);

  if (typeof data !== 'object' || data === null) {
    return <span>{JSON.stringify(data)}</span>;
  }

  const isArray = Array.isArray(data);
  const isEmpty = Object.keys(data).length === 0;

  return (
    <div className="text-s" style={{ marginLeft: `${depth * 4}px` }}>
      <span onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
        {isOpen ? '▼' : '▶'} {/* Toggle arrow */}
        {isArray ? '[' : '{'}
        {isEmpty && (isArray ? ']' : '}')}
      </span>

      {isOpen && !isEmpty && (
        <>
          <div className="text-s">
            {Object.entries(data).map(([key, value]) => (
              <div key={key}>
                <span>{key}: </span>
                <ObjectExplorer data={value} depth={depth + 1} />
              </div>
            ))}
          </div>
          <div style={{ marginLeft: `${depth * 4}px` }}>
            {isArray ? ']' : '}'}
          </div>
        </>
      )}
    </div>
  );
}

export default function DataTab() {
  const config = $config.get();
  return (
    <div>
      <ObjectExplorer data={config} />
    </div>
  );
}
