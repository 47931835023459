import { useEffect, useState } from 'react';

import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import { injectTakeoverTemplate } from '@client/xandr/components/takeover/util';
import { checkForNoBidCreatives } from '@client/xandr/mediationNoBidHandler';
import { setPageOptsInXandr } from '@client/xandr/setPageOpts';
import { getTagConfig } from '@client/xandr/tag';
import {
  defineTag,
  getDebouncedLoadTagsFunction,
  horseshoePlacements,
  onAdAvailable,
  onAdBadRequest,
  onAdLoaded,
  onAdLoadedMediated,
  onAdNoBid,
  onAdNoBidMediated,
  onAdRequested,
  XandrLifecycleEvents
} from '@client/xandr/xandr';
import {
  AdObj,
  debugLog,
  isPremiumNativeAd,
  isTakeover,
  NativeAdObj,
  PlacementId,
  PlacementStatusesEnum,
  RecommendationCategories
} from '@schibsted-nmp/advertising-shared';

import { $config, $waitForClient } from '../atoms/config';
import { updateMetricByKey } from '../atoms/metrics';
import { getConsentOrSubscribe } from '../utils/consent';

export type SetupAdProps = {
  placementId: PlacementId; // The actual placementId xandr uses
  targetId: string; // Used for cycling and actual targeting
  containerId: string; // The container the ad is loaded into from renderAd()
  categories?: RecommendationCategories;
};

export function useSetupAd({
  placementId,
  targetId,
  categories
}: SetupAdProps) {
  const [adObj, setAdObj] = useState<AdObj | null>(null);

  const tagConfig = getTagConfig(
    placementId as PlacementId,
    targetId,
    categories
  );

  useEffect(() => {
    function setCreativeType(_adObj: AdObj) {
      const deviceType = $config.get()?.deviceType;

      let creativeType = _adObj?.adType;

      if (isTakeover(_adObj, deviceType)) {
        creativeType = 'takeover';
        injectTakeoverTemplate(placementId);
      }

      if (isPremiumNativeAd(_adObj as NativeAdObj)) {
        creativeType = 'sponsored-content';
      }
      updatePlacementKeyValueById(placementId, 'creativeType', creativeType);
    }
    onAdRequested(targetId, () => {
      updateMetricByKey(placementId, PlacementStatusesEnum.AdRequested);
      updatePlacementKeyValueById(placementId, 'status', 'requested');
      debugLog(placementId, `Requested ${targetId}`, 'Requested');
    });

    onAdAvailable(targetId, (_adObj: AdObj) => {
      if (checkForNoBidCreatives(placementId, _adObj.creativeId)) {
        updatePlacementKeyValueById(placementId, 'status', 'error');
        return;
      }
      setCreativeType(_adObj);
      updatePlacementKeyValueById(placementId, 'status', 'available');
      debugLog('adAvailable:', { adObj: _adObj });
      setAdObj(_adObj);
    });

    onAdLoaded(targetId, (_adObj: AdObj) => {
      updateMetricByKey(placementId, PlacementStatusesEnum.AdLoaded);
      setCreativeType(_adObj);
      updatePlacementKeyValueById(placementId, 'status', 'loaded');
      setAdObj(_adObj);
    });

    onAdLoadedMediated(targetId, () => {
      updatePlacementKeyValueById(placementId, 'status', 'loaded');
    });

    onAdBadRequest(targetId, () => {
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    onAdNoBid(targetId, () => {
      updateMetricByKey(placementId, PlacementStatusesEnum.AdNoFill);
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    onAdNoBidMediated(targetId, () => {
      updatePlacementKeyValueById(placementId, 'status', 'error');
    });

    function loadAd() {
      const waitForClient = $waitForClient.get();
      if (waitForClient) {
        $waitForClient.subscribe((wait) => {
          if (!wait) {
            getDebouncedLoadTagsFunction()();
          }
        });
      } else {
        getDebouncedLoadTagsFunction()();
      }
    }

    getConsentOrSubscribe((permissionValue) => {
      debugLog('getConsentOrSubscribe ', { permissionValue });
      setPageOptsInXandr(permissionValue);
      if (tagConfig) {
        // We are seeing so many blanks on FINN and heard that this might be caused by too many placements being loaded at the same time.
        // Trying to define horseshoe specific placements first and wait 200ms for the rest.
        // This should chunk the load into two /v3 requests; one for horseshoe placements and one for the rest
        if (horseshoePlacements.includes(placementId)) {
          defineTag(tagConfig);
          loadAd();
        } else {
          setTimeout(() => {
            defineTag(tagConfig);
            loadAd();
          }, 300);
        }
      }
    });

    const cleanupEvents = () => {
      if (!window.apntag?.anq) return;

      [
        XandrLifecycleEvents.AD_REQUESTED,
        XandrLifecycleEvents.AD_AVAILABLE,
        XandrLifecycleEvents.AD_LOADED,
        XandrLifecycleEvents.AD_NO_BID,
        XandrLifecycleEvents.AD_BAD_REQUEST
      ].forEach((eventType) => {
        window.apntag.anq.push(() =>
          window.apntag?.offEvent(eventType, targetId)
        );
      });
    };

    return () => {
      setAdObj(null);
      cleanupEvents();
    };
  }, [targetId]);

  return { adObj };
}
