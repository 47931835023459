import React from 'react';

import { Native } from '@schibsted-nmp/advertising-shared';

import { escapeString } from '../native.helper';

type Props = {
  native: Native;
  className?: string;
  margin?: string;
  size?: number;
  // We always set either width or height, to let the image grow naturally the other way
  constrainWidth?: boolean;
};
function NativeLogo(props: Props) {
  const { native, constrainWidth, className = '', size = 40 } = props;
  let logoUrl: string;
  if (native && native.icon && native.icon.url) {
    logoUrl = escapeString(native.icon.url);
  } else {
    return null;
  }

  const altText = escapeString(native.sponsoredBy ? native.sponsoredBy : '');

  const wideOrTallStyle = constrainWidth
    ? { width: size, minWidth: size }
    : { height: size, minHeight: size };

  return (
    <img
      src={logoUrl}
      className={className}
      style={wideOrTallStyle}
      alt={altText}
      width={constrainWidth ? size : ''}
      height={!constrainWidth ? size : ''}
    />
  );
}

export default NativeLogo;
