import React, { useState } from 'react';

import { $config } from '@client/core/atoms/config';

import DataTab from './components/DataTab';
import ErrorTab from './components/ErrorTab';
import { FeatureFlagTab } from './components/FeatureFlagTab';
import { PlacementsTab } from './components/PlacementsTab';
import { SignificantEventsTab } from './components/SignificantEventsTab';
import { TabButton } from './components/TabButton';

const DevPanel = () => {
  const [tabState, setTabState] = useState<
    'placements' | 'featureFlags' | 'errors' | 'significantEvents' | 'data'
  >('placements');

  function getInfo() {
    const config = $config.get();
    const pageType = config?.pageType;
    const deviceType = config?.deviceType;
    const brand = config?.brand;
    const vertical = config?.vertical;
    const hasConsent = config?.consent;
    return `${brand} - ${vertical} - ${deviceType} - ${pageType} - Consent: ${hasConsent}`;
  }

  const renderTabContent = () => {
    switch (tabState) {
      case 'placements':
        return <PlacementsTab />;
      case 'featureFlags':
        return <FeatureFlagTab />;
      case 'significantEvents':
        return <SignificantEventsTab />;
      case 'errors':
        return <ErrorTab />;
      case 'data':
        return <DataTab />;
      default:
        return null;
    }
  };

  return (
    <div>
      <p className="font-bold">{getInfo()}</p>

      <div className="flex gap-4 border-b mb-8 flex-wrap">
        <TabButton
          label="Placements"
          active={tabState === 'placements'}
          onClick={() => setTabState('placements')}
        />
        <TabButton
          label="Feature Flags"
          active={tabState === 'featureFlags'}
          onClick={() => setTabState('featureFlags')}
        />
        <TabButton
          label="Events"
          active={tabState === 'significantEvents'}
          onClick={() => setTabState('significantEvents')}
        />
        <TabButton
          label="Errors"
          active={tabState === 'errors'}
          onClick={() => setTabState('errors')}
        />
        <TabButton
          label="Data"
          active={tabState === 'data'}
          onClick={() => setTabState('data')}
        />
      </div>

      {renderTabContent()}
    </div>
  );
};
export default DevPanel;
