import { $config } from '@client/core/atoms/config';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

export function shouldRenderSponsoredContent(placementId: PlacementId) {
  const { brand, vertical, subvertical, pageType } = $config.get();

  const isRealEstate = vertical === 'realestate';
  const isRealEstateSubverticalMatch =
    isRealEstate && (subvertical === 'homes' || subvertical === 'newbuildings');
  const isCar = vertical === 'mobility';
  const isCarSubverticalMatch = isCar && subvertical === 'used';
  const sponsoredContentEnabledSubVertical =
    isRealEstateSubverticalMatch || isCarSubverticalMatch;
  const sponsoredContentEnabledBrand = brand === 'finn';
  const allowedPlacements = [PlacementId.Middle2];
  const sponsoredContentEnabledPlacement =
    allowedPlacements.includes(placementId);
  const sponsoredContentEnabledPageType = pageType !== 'object';

  return (
    sponsoredContentEnabledSubVertical &&
    sponsoredContentEnabledBrand &&
    sponsoredContentEnabledPlacement &&
    sponsoredContentEnabledPageType
  );
}
