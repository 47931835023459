import { AdObj } from './types/xandr';
import { BannerAdObj } from './types/xandr/bannerAd';
import { NativeAdObj } from './types/xandr/nativeAd';
import { DeviceType } from './types/api/adConfig';

export function isBannerAd(adObj: AdObj): adObj is BannerAdObj {
  return adObj.adType === 'banner';
}

export function isNativeAd(adObj: AdObj): adObj is NativeAdObj {
  return adObj && adObj.adType === 'native';
}

export function isAdnamiAd(adObj: BannerAdObj) {
  return (
    (adObj?.adType === 'banner' && adObj?.width === 1 && adObj?.height === 2) ||
    (adObj?.banner?.width === 1 && adObj?.banner?.height === 2) ||
    adObj?.banner?.content?.includes('adnm') ||
    adObj?.banner?.content?.includes('adnami')
  );
}

export function isVideoAd(adObj: AdObj) {
  return adObj.adType === 'video';
}

export function isTakeover(adObj: AdObj, deviceType: DeviceType) {
  return (
    deviceType === 'mobile' &&
    adObj.adType === 'banner' &&
    adObj.source === 'rtb' &&
    !!adObj.height &&
    !!adObj.width &&
    ((adObj.height === 1080 && adObj.width === 1000) ||
      (adObj.height === 1080 && adObj.width === 1920))
  );
}

export function isPremiumNativeAd(adObj: NativeAdObj) {
  if (!adObj || !adObj?.native) return false;
  return (
    adObj.native?.desc2 === 'content' ||
    adObj.native?.custom?.type === 'content'
  );
}
