import {
  fetchElement,
  fetchElementById
} from '@client/core/utils/dom/domUtils';

const FILTER_LIST_ID = 'advertising-tori-filters-bottom-marker';
const MIDDLE_2_ADVT_COMPONENT = 'advt-component[placementid="advt_middle_2"]';
const MARGIN = 100;
let filterListElement: HTMLElement | null = null;

const fetchFilterListElement = () => {
  filterListElement ??= fetchElementById(FILTER_LIST_ID);
};

export const handleMiddle2OverlapsWithFilter = () => {
  fetchFilterListElement();
  const middle2 = fetchElement(MIDDLE_2_ADVT_COMPONENT);
  if (!filterListElement || !middle2) return false;

  const filterBottomPlacement =
    filterListElement.getBoundingClientRect().bottom + window.scrollY + MARGIN;
  const middle2PlacementTop =
    middle2.getBoundingClientRect().top + window.scrollY;

  return middle2PlacementTop < filterBottomPlacement;
};
