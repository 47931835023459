import { initiateGam } from '@client/adManager/initiateGam';
import { AdnAdVendor } from '@client/adnuntius/AdnAdVendor';
import { initiateAdn } from '@client/adnuntius/initiateAdn';
import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import { setupEvents } from '@client/core//utils/events';
import { setupOverrideHelper } from '@client/core//utils/overrideHelper';
import { defineAdvtComponent } from '@client/core/components/webComponents';
import { sendErrorMessageMetricToServer } from '@client/core/services/metrics';
import { getConsentOrSubscribe } from '@client/core/utils/consent';
import { initiateHandleStickySkyscrapers } from '@client/core/utils/dom/handleAdsOverlap/handleStickySkyscrapers';
import { persistPPID } from '@client/core/utils/pulse/pulse';
import {
  AdServer,
  debugLog,
  InitialState
} from '@schibsted-nmp/advertising-shared';

import { loadGlimrIfPermission } from './core/utils/glimr';
import './local/AdvtDevTool';
import { initFeed } from './xandr/feed';
import { setPageOptsInXandr } from './xandr/setPageOpts';

/**
 * Initiates ad rendering, setup page options and handles each placement.
 * @param initialState The initial state for setting up ads.
 */
export async function initiateAdvt(initialState: InitialState): Promise<void> {
  try {
    debugLog('Inside initiateAdvt()');

    const { config } = initialState;

    await Promise.all([
      await persistPPID(),
      loadGlimrIfPermission(config.brand)
    ]);

    if (config.adServer.xandr) {
      getConsentOrSubscribe((permissionValue) => {
        setPageOptsInXandr(permissionValue);
      });
    }

    setupEvents();

    if (config?.adServer.xandr) {
      setupOverrideHelper(
        config.placements.filter((p) => p.adServer.type === AdServer.Xandr)
      );
    }

    if (config.deviceType === 'desktop') {
      initiateHandleStickySkyscrapers();
    }

    if (config.brand?.toLowerCase() === 'finn') {
      if (config?.adServer?.xandr?.feed) {
        debugLog('Setting up feed');
        initFeed(config.adServer.xandr.feed);
      }

      triggerFinnAfsRequest();
    }

    defineAdvtComponent();

    if (config?.adServer.gam) {
      initiateGam(config.adServer.gam);
    }

    if (config?.adServer.adn) {
      initiateAdn(config.adServer.adn);
      AdnAdVendor.setupBatchRequesting();
    }
    debugLog('Advt initiated');
  } catch (e) {
    console.error('Failed to initiate ads', e);
    sendErrorMessageMetricToServer(`Failed to initiate ads ${e.message}`).then(
      () => {
        debugLog('Sent error message metric to server');
      }
    );
  }
}
