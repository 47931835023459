import { useEffect } from 'react';

import { debugLog } from '@schibsted-nmp/advertising-shared';

export const useRemoveUtifSizes = (targetId: string) => {
  // Hack to fix Relevant Digital & Xandr size bug
  useEffect(() => {
    const id = `div_utif_${targetId}`;
    const utifDiv = document.getElementById(id);
    if (utifDiv) {
      debugLog(
        `[SIZE_CHANGE] ${targetId} Removing utifDiv styles for ${targetId}`
      );
      // remove all utifDiv styles
      utifDiv.style.height = '';
      utifDiv.style.width = '';
    }
  });
};
