import { atom } from '@podium/store';
import {
  PlacementId,
  PlacementStatusesEnum,
  PlacementStatusMetric
} from '@schibsted-nmp/advertising-shared';
import { ClientAdPlacement } from '@schibsted-nmp/advertising-shared/src/types/client';

import { getPlacementById } from './placements';

import { AtomChannels, AtomTopics } from '.';

export const $placementStatusMetrics = atom<PlacementStatusMetric[]>(
  AtomChannels.Podlet,
  AtomTopics.PlacementStatusMetrics,
  []
);

export const $lifecycleEvents = atom<string[]>(
  AtomChannels.Podlet,
  AtomTopics.SignificantEvents,
  []
);

export function addLifecycleEvent(event: string) {
  $lifecycleEvents.set([...$lifecycleEvents.get(), event]);
}

export const $placementMetricSequence = atom<
  Record<string, PlacementStatusesEnum[]>
>(AtomChannels.Podlet, AtomTopics.PlacementMetricSequence, {});

export function addToMetricsSequence(
  placementId: PlacementId,
  status: PlacementStatusesEnum
) {
  const currentStatusSequence =
    $placementMetricSequence.get()[placementId] || [];
  if (status) {
    if (
      status === PlacementStatusesEnum.InConfig &&
      currentStatusSequence.includes(status)
    ) {
      // Skip multiple in_config statuses
      return;
    }
    $placementMetricSequence.set({
      ...$placementMetricSequence.get(),
      [placementId]: [...currentStatusSequence, status]
    });
  }
}

export function updateMetricByKey(
  placementId: PlacementId,
  key: PlacementStatusesEnum
) {
  const oldMetrics = $placementStatusMetrics.get();
  let placementExists = false;
  const updatedMetrics: PlacementStatusMetric[] = oldMetrics.map(
    (oldMetric) => {
      if (oldMetric.placementId === placementId) {
        placementExists = true;
        // Only add the key if it doesn't already exist in statuses
        if (!oldMetric.statuses.includes(key)) {
          return {
            ...oldMetric,
            statuses: [...oldMetric.statuses, key]
          };
        }
      }
      return oldMetric;
    }
  );

  if (!placementExists) {
    updatedMetrics.push({
      placementId,
      adServer: getPlacementById(placementId)?.adServer.type,
      statuses: [key]
    });
  }
  addToMetricsSequence(placementId, key);

  $placementStatusMetrics.set(updatedMetrics);
}

export function setInitialPlacementStatusMetrics(
  placements: ClientAdPlacement[]
) {
  $placementStatusMetrics.set([]);
  placements.forEach((placement) => {
    updateMetricByKey(placement.placementId, PlacementStatusesEnum.InConfig);
  });
}

export function setAllRefreshMetrics(placements: ClientAdPlacement[]) {
  placements.forEach((placement) => {
    updateMetricByKey(placement.placementId, PlacementStatusesEnum.Refresh);
  });
}
