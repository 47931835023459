import React from 'react';

import classNames from 'classnames';

import { $config } from '@client/core/atoms/config';
import { useLoadNativeAd } from '@client/core/hooks';
import { Label } from '@client/xandr/components/native/parts/Label';
import NativeCogwheel from '@client/xandr/components/native/parts/NativeCogwheel';
import { RecommendationNativeAd } from '@client/xandr/components/native/RecommendationNativeAd';
import { i18n } from '@lingui/core';
import {
  LayoutType,
  NativeAdObj,
  PlacementId,
  Vertical
} from '@schibsted-nmp/advertising-shared';
import {
  MotorNativeAdvertisement as DefaultNativeAdvertisement,
  RealestateNativeAdvertisement
} from '@schibsted-nmp/search-frontend-components/react/advertising';

import { escapeString, getDescription } from './native.helper';
import Comment from './parts/Comment';
import NativeLogo from './parts/NativeLogo';

export type NativeAdProps = {
  adObj: NativeAdObj;
  targetId: string;
  placementId: PlacementId;
  className?: string;
  layoutType?: LayoutType;
  pageType?: string;
  vertical?: string;
};

function getExtraInset(
  pageType: string,
  vertical: Vertical,
  isRecircAd: boolean,
  layoutType?: LayoutType
): string {
  if (layoutType === 'list') return '0';
  if (isRecircAd && pageType === 'object') return '8';
  const extraCogwheelInset: Vertical[] = ['mobility'];
  return extraCogwheelInset?.includes(vertical) ? '10' : '0';
}

export function NativeAdComponent(props: NativeAdProps) {
  const { vertical, pageType } = $config.get();

  const ref = React.useRef(null);
  const { targetId, adObj, layoutType } = props;
  useLoadNativeAd(adObj, targetId, props.placementId, ref);

  const { native } = adObj;
  const layout = (layoutType ?? 'grid') as any;
  const isListLayout = layout === 'list';

  const ad = {
    id: `native-${adObj.targetId}`,
    heading: native.title,
    organisation_name: getDescription(native),
    image: { ...native.image, path: native.image.url },
    clickUrl: native.clickUrl
  };

  const adLabel = i18n.t({
    id: 'native.advertisement',
    message: 'advertisement'
  });

  const isRecircAd =
    targetId.includes('recirc') || targetId.includes('ad-stream');

  // Use this style for both recommendation ads, and object page native ads
  if (isRecircAd || pageType === 'object') {
    return (
      <div id={targetId} ref={ref} className="h-full">
        <RecommendationNativeAd
          {...props}
          pageType={pageType}
          vertical={vertical}
        />
      </div>
    );
  }
  switch (vertical) {
    case 'realestate': {
      return (
        <div className="h-full" id={targetId} ref={ref as any}>
          <RealestateNativeAdvertisement
            ad={ad as any}
            url={native.clickUrl}
            className="f-grid w-full h-full"
            label={({ classes }) => (
              <Label classes={classes} adLabel={adLabel} />
            )}
            sponsorName={() => (
              <div className="flex gap-16 mb-12">
                <NativeLogo native={native} constrainWidth={isListLayout} />
                <div className="flex items-center">
                  {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
                </div>
              </div>
            )}
            contentBottom={() => (
              <div className="mt-8">
                <span>{getDescription(native)}</span>
                <Comment
                  placement={`RealestateNativeAdvertisement - ${vertical}`}
                  adObj={adObj}
                />
                <NativeCogwheel
                  extraInset={getExtraInset(
                    pageType,
                    vertical,
                    isRecircAd,
                    layoutType
                  )}
                  adObj={adObj}
                />
              </div>
            )}
          />
        </div>
      );
    }

    // Use this for default because it is covering a bunch of different verticals
    case 'recommerce':
    case 'mc':
    case 'boat':
    case 'motor':
    case 'b2b':
    case 'mobility':
    case 'travel':
    default: {
      return (
        <div className="h-full" id={targetId} ref={ref as any}>
          <DefaultNativeAdvertisement
            key={layout}
            ad={ad as any}
            className={classNames('f-grid w-full h-full', {
              'bigger-native-ad': vertical !== 'travel',
              'shadow hover:ring focus:ring outline-none m-0':
                vertical === 'travel',
              'f-grid shadow-none': vertical === 'recommerce'
            })}
            layout={layout}
            url={native.clickUrl}
            label={({ classes, className }) => (
              <Label
                className={classNames(className, {
                  'rounded-tl-8 rounded-br-4': vertical === 'travel'
                })}
                classes={classes}
                adLabel={adLabel}
              />
            )}
            sponsorName={() => (
              <div>
                {escapeString(native.sponsoredBy ? native.sponsoredBy : '')}
              </div>
            )}
            contentBottom={() => (
              <div className="mt-auto">
                <div className={classNames('flex gap-4 flex-col', {})}>
                  <div>{getDescription(native)}</div>
                  <NativeLogo
                    className="h-auto mt-auto ml-auto"
                    constrainWidth={true}
                    size={40}
                    native={native}
                  />
                </div>
                <Comment
                  placement={`MotorNativeAdvertisement - ${vertical}`}
                  adObj={adObj}
                />
                <NativeCogwheel
                  extraInset={isListLayout ? '0' : '8'}
                  adObj={adObj}
                />
              </div>
            )}
          />
        </div>
      );
    }
  }
}
