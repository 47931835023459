/**
 * This method returns the configuration for AFS for FINN.no, since the FINN config provider API
 * doesn't yet support server-side values. This method should be removed once the API is updated.
 */
import { triggerAfsRequest } from '@client/adsense/index';
import { $config } from '@client/core/atoms/config';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

const CONTAINER_ID = 'afscontainer1';
const STYLE_ID = {
  DESKTOP: '5268803732',
  MOBILE: '4302767753'
};

const afs = {
  pubId: 'partner-pub-4700071094164059',
  query: 'search'
};
function getQueryFromElement() {
  const el = document.getElementById(CONTAINER_ID);
  if (el) {
    const query = el.getAttribute('data-query');
    if (query) {
      return query;
    }
    const category = el.getAttribute('data-category');
    if (category) {
      return category;
    }
    const subCategory = el.getAttribute('data-subcategory');
    if (subCategory) {
      return subCategory;
    }
  }
  return '';
}

export function triggerFinnAfsRequest() {
  const query = getQueryFromElement();

  const isMobile = $config.get().deviceType === 'mobile';

  triggerAfsRequest({
    container: CONTAINER_ID as PlacementId,
    query,
    number: 1,
    adPage: 'search',
    pubId: afs.pubId,
    styleId: isMobile ? STYLE_ID.MOBILE : STYLE_ID.DESKTOP
  });
}
