import { WindowAdvt } from './types/state';

export function setAdvtState(key: string, value: any) {
  if (typeof window !== 'undefined') {
    window.ADVT = {
      ...((window.ADVT || {}) as WindowAdvt),
      [key]: value
    };
  }
}
