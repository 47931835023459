import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { refreshKeywords } from '@client/core/utils/refreshKeywords';
import { $config, $waitForClient } from '@client/core/atoms/config';
import { setXandrPageOpts } from '@client/core/atoms/xandrPageOpts';
import { debugLog } from '@schibsted-nmp/advertising-shared';

type TravelPageLoadedEvent = {
  payload: {
    pageType: string;
    page?: number;
    destination?: {
      continent?: string;
      country?: string;
      city?: string;
    };
    destinasjon?: {
      continent?: string;
      country?: string;
      city?: string;
    };
    subvertical: string;
  };
};

export function setupTravelEvents() {
  const { pageType, subvertical } = $config.get();

  if (pageType === 'result' && subvertical !== 'hotel') {
    messageBus.subscribe(
      events.SEARCH.channel,
      events.SEARCH.FILTER.topic,
      (event) => {
        refreshKeywords({
          ...event,
          payload: { searchParams: event.payload }
        });
      }
    );
  }

  // Used by the travel vertial to set keywords on page load because they're not getting all the proper pageOpts
  const peekEvent = messageBus.peek('page', 'loaded') as TravelPageLoadedEvent;
  if (peekEvent) {
    travelLoadedCallback(peekEvent);
  } else {
    messageBus.subscribe('page', 'loaded', (e) => {
      travelLoadedCallback(e as TravelPageLoadedEvent);
    });
  }
}
const travelLoadedCallback = (event: TravelPageLoadedEvent) => {
  $waitForClient.set(true);

  debugLog('Travel page loaded event received', event);
  setKeywordsFromTravelLoadedEvent(event);
  refreshKeywords({
    ...event,
    payload: { searchParams: event.payload }
  }).then(() => {
    $waitForClient.set(false);
  });
};

function setKeywordsFromTravelLoadedEvent(event: TravelPageLoadedEvent) {
  const configFromTravelLoadedEvent = event.payload;

  const { vertical, subvertical, pageType } = {
    ...$config.get(),
    ...configFromTravelLoadedEvent
  };

  const keywords = {};

  if (vertical && subvertical) {
    keywords['no-sno-finn-subsection'] = [`${vertical}_${subvertical}`];
  }

  if (pageType) {
    keywords['aa-sch-page_type'] = [pageType as string];
  }

  setXandrPageOpts({
    keywords
  });
}
