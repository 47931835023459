import { hasQueryParam } from './debugLog';
import { setAdvtState } from './safeSetAdvtState';

const ENABLED_PARAM = 'advt_dev_tool';
let isDevToolOn: boolean | undefined;

function toggleIsOn() {
  const toggleInLocalStorage =
    window.localStorage.getItem(ENABLED_PARAM) === 'true';
  return toggleInLocalStorage || hasQueryParam(ENABLED_PARAM);
}

setAdvtState('toggleDevTool', () => {
  const localStorageHasDebugFlag =
    window.localStorage.getItem(ENABLED_PARAM) === 'true';

  if (localStorageHasDebugFlag) {
    window.localStorage.removeItem(ENABLED_PARAM);
    return false;
  }
  window.localStorage.setItem(ENABLED_PARAM, 'true');
  return true;
});

export function devToolToggled() {
  if (isDevToolOn === undefined) {
    isDevToolOn = toggleIsOn();
  }
  return isDevToolOn;
}
