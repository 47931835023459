import {
  $batchRequestAdQueue,
  $requestedPlacements
} from '@client/core/batchRequesting/batchRequestState';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import {
  AdConfigPlacement,
  AdServer,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

import { $config } from '../atoms/config';

const REQUEST_DEBOUNCE = 200;
export function setupBatchRequestingForVendor({
  adServer,
  requestAds,
  overrideRequestDebounce
}: {
  adServer: AdServer;
  requestAds: (placements: AdConfigPlacement[]) => void;
  overrideRequestDebounce?: number;
}) {
  const config = $config.get();
  const hasAdserverConfig = adServer in config.adServer;
  if (!hasAdserverConfig) return;
  let debounceTimer: ReturnType<typeof setTimeout> | null = null;

  $batchRequestAdQueue.subscribe((queuedPlacementIds) => {
    if (queuedPlacementIds.length === 0) return;

    const placements: AdConfigPlacement[] = [];

    queuedPlacementIds.forEach((placementId) => {
      const placement = config.placements.find(
        (p) => p.placementId === placementId
      );
      if (placement?.adServer.type === adServer) placements.push(placement);
    });

    if (placements.length === 0) return;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    debounceTimer = setTimeout(() => {
      publishAdStatus(placements);

      requestAds(placements);

      const placementIds = placements.map((p) => p.placementId as PlacementId);
      updateBatchRequestState(placementIds);
    }, overrideRequestDebounce ?? REQUEST_DEBOUNCE);
  });
}

function publishAdStatus(placements: AdConfigPlacement[]) {
  placements.forEach((placement) => {
    const containerId = `${placement.placementId}--container`;
    messageBus.publish(
      events.PODLET.channel,
      events.PODLET.AD_CONTAINER_STATUS.topic,
      events.PODLET.AD_CONTAINER_STATUS.sendPayload({
        status: 'loaded',
        adType: 'banner',
        containerId
      })
    );
  });
}

function updateBatchRequestState(newRequestedPlacementIds: PlacementId[]) {
  const placementsToAdd = newRequestedPlacementIds?.filter(
    (placementId) =>
      !$requestedPlacements.value?.find(
        (requestedPlacementId) => requestedPlacementId === placementId
      )
  );
  if (!placementsToAdd?.length) return;
  $requestedPlacements.set([
    ...($requestedPlacements.value ?? []),
    ...newRequestedPlacementIds
  ]);

  const allRequestedPlacements = $requestedPlacements.value || [];
  $batchRequestAdQueue.set(
    allRequestedPlacements.filter(
      (placementId) => !newRequestedPlacementIds.includes(placementId)
    )
  );
}
