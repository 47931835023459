import classNames from 'classnames';
import { css } from 'lit';

import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import {
  AdStatus,
  AdType,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

export function getClassNameByStatus(status: AdStatus) {
  const invisibleInsteadOfHidden = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamForecasting
  );
  return classNames({
    loaded: status === 'available' || status === 'loaded',
    loading: status === 'pending' || status === 'refresh',
    hidden: status === 'error' && !invisibleInsteadOfHidden,
    'hidden-for-forecasting': status === 'error' && invisibleInsteadOfHidden
  });
}

export function getClassNameByType(type: AdType) {
  return classNames({
    'native-ad': type === 'native',
    'video-ad': type === 'video',
    'banner-ad': type === 'banner',
    'sponsored-content': type === 'sponsored-content',
    'takeover-ad': type === 'takeover'
  });
}

export default css`
  :host(.advt-empty) {
    display: none;
  }
  :host(.advt-x) {
    display: block;
    position: relative;
    z-index: 2;
  }

  :host(.advt-g) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
    width: 100%;
    min-height: 10px;
  }

  :host(.banner-ad.object-page[placementid*='advt_recirc_middle_2']) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
  }

  :host(.banner-ad.result-page[placementid*='advt_middle']) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
  }

  :host(.takeover-ad[placementid*='middle_']) {
    margin: 0 auto;
    grid-column: 1 / -1;
    width: 100%;
    overflow: visible;
  }

  :host([placementid='advt_wallpaper']) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50vw);
    z-index: -1;
    width: 100vw;
    overflow: hidden;
  }

  :host([placementid='advt_top_2']) {
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    margin: 12px 0;
    overflow: hidden;
  }

  /* Handle 5 rows banner ad in recirc placement 1 */
  :host(.banner-ad[placementId='advt_recirc_middle_1']) {
    grid-column: 1 / 3;
  }

  :host([placementid='advt_bottom_1']) {
    overflow: visible;
    margin: 20px 0;
  }

  :host([placementid='advt_bottom_1']) #advt_bottom_1 {
    margin-left: auto;
  }

  :host([placementid*='advt_recirc_middle_']) #advt_bottom_1 {
    width: 100%;
  }

  :host(.video-ad) {
    margin: 0 auto;
    grid-column: 1 / -1;
  }

  :host(.video-ad[placementid*='middle_']) iframe {
    margin-left: auto;
    margin-right: auto;
  }

  :host(.sponsored-content) {
    overflow: hidden;
    grid-column: 1 / -1;
    width: calc(100% + 8px);
    margin: 0px -4px;
    padding: 8px;
  }

  :host(.native-ad) {
    height: 100%;
  }

  :host(.native-ad[placementid*='middle_']) {
    grid-column: unset;
    margin: unset;
    overflow: visible;
  }

  @media (max-width: 989px) {
    :host(.banner-ad[placementid*='advt_recirc_middle_1']) {
      margin: 0 auto;
      overflow: hidden;
      grid-column: 1 / -1;
    }
  }

  :host(.loaded) {
    display: block;
  }

  :host(.loading) {
    position: relative;
  }

  :host(.hidden) {
    display: none;
  }

  :host(.hidden-for-forecasting[placementid*='middle_']) {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    grid-column: 1 / -1;
    visibility: hidden;
  }

  :host(.banner-ad[placementid*='advt_wallpaper']) {
    max-width: 100vw;
    overflow: hidden;
  }

  :host(.banner-ad[placementid*='right']),
  :host(.banner-ad[placementid*='left']) {
    position: sticky;
    transform: translateZ(0);
    z-index: 2;
    top: 66px;
  }

  /* Hide Recommendation ads until they are initialized by the podlet */
  :host(:not(.loaded)):host([placementid*='ad-stream__native-ad']),
  :host(:not(.loaded)):host([placementid*='advt_recirc_middle']) {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    grid-column: 1 / -1;
    visibility: hidden;
  }

  /* Don't overflow for mobile devices on middle placements, it breaks the UI */
  @media (max-width: 767px) {
    :host(.banner-ad[placementid*='bottom_1']),
    :host(.banner-ad[placementid*='middle_']),
    :host(.banner-ad[placementid*='advt_top_2']) {
      overflow: hidden;
      max-width: 100%;
    }

    /* Not sure why this is here but it was way too generic so I had to add specificity */
    :host(.banner-ad.loaded),
    :host(.native-ad.loaded),
    :host(.video-ad.loaded) {
      overflow: hidden;
    }
  }
`;
