import { RefObject, useCallback, useEffect, useState } from 'react';

import { sizeIsNativeAd } from '@client/adManager/util';
import { $config, $initialState } from '@client/core/atoms/config';
import { $gamTargetingAtom } from '@client/core/atoms/gamTargeting';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import {
  getAllFeatureStatuses,
  isFeatureEnabled
} from '@client/core/atoms/unleashFeatures';
import { useInScreen } from '@client/core/hooks';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId';
import {
  debugLog,
  GamConfig,
  GamEventListenerEventName,
  GamPlacement,
  getSizesByMediaType,
  PlacementId,
  PlacementStatusesEnum,
  Slot,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

import { getDebouncedLoadAdFunction } from '../batch';
import { setTargetingOnSlotOrGlobal } from '../targeting';

type Props = {
  containerId: string;
  placement: GamPlacement;
  ref: RefObject<HTMLDivElement>;
  forecasting?: boolean;
};

const setupGamEventListeners = (slot: Slot) => {
  const placementId = slot.getSlotElementId() as PlacementId;

  const events: GamEventListenerEventName[] = [
    // Fired when an ad has been requested for the ad slot.
    'slotRequested',
    // Fired when creative code has been injected into an ad slot.
    'slotRenderEnded',
    // Fired when an impression becomes viewable.
    'impressionViewable',
    // Fired when the on-screen percentage of the ad slot changes.
    'slotVisibilityChanged',
    // Fired when a creative iframe fires its onload event.
    'slotOnload'
  ];
  events.forEach((eventType) => {
    window.googletag.pubads().addEventListener(eventType, (event) => {
      const eventSlot = event.slot;
      const eventElemId = eventSlot.getSlotElementId();

      if (eventElemId !== placementId) {
        // Skip events that are not related to the current slot
        return;
      }

      if (eventType !== 'slotVisibilityChanged') {
        debugLog(`PlacementId: ${placementId}: ${eventType} event:`, event);
      }

      if (eventType === 'slotRequested') {
        debugLog(
          `Google ad requested for placement: ${placementId} with slot`,
          eventSlot
        );
        updatePlacementKeyValueById(placementId, 'creativeType', 'banner');
        // update placement atoms with the new status
        updatePlacementKeyValueById(placementId, 'status', 'requested');

        updateMetricByKey(placementId, PlacementStatusesEnum.AdRequested);
      }

      if (eventType === 'slotRenderEnded') {
        if (event?.isEmpty) {
          debugLog(
            `Google ad did not fill for placement: ${placementId} with slot`,
            eventSlot
          );
          updateMetricByKey(placementId, PlacementStatusesEnum.AdNoFill);
          updatePlacementKeyValueById(placementId, 'status', 'error');
        } else {
          const nativeAdTypesEnabled = isFeatureEnabled(
            UNLEASH_FEATURE_NAME.enableNativeAdTypes
          );
          const creativeType =
            nativeAdTypesEnabled && sizeIsNativeAd(event.size)
              ? 'native'
              : 'banner';
          debugLog(`Creative type from size ${event.size}: ${creativeType}`);
          updatePlacementKeyValueById(
            placementId,
            'creativeType',
            creativeType
          );
          updatePlacementKeyValueById(placementId, 'status', 'loaded');
          updateMetricByKey(placementId, PlacementStatusesEnum.AdLoaded);
        }
      }

      if (eventType === 'impressionViewable') {
        updateMetricByKey(placementId, PlacementStatusesEnum.Viewed);
      }
    });
  });
};

function ensurePathStartsWithSlash(path: string): string {
  if (!path.startsWith('/')) {
    return `/${path}`;
  }
  return path;
}

export function useInitiateGamUnit(props: Props) {
  const { adServer } = $config.get();
  const gamConfig = adServer.gam as GamConfig;
  const { placement } = props;
  const { placementId } = placement;
  const isInView = useShouldLoadAd({
    placementId,
    ref: props.ref
  });
  const [slotState, setSlotState] = useState<Slot | null>(null);

  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((slot: Slot | null) => {
    if (slot) {
      getDebouncedLoadAdFunction()(slot);
    }
  }, []);

  const [shouldLoadAd, setShouldLoadAd] = useState(true);

  /**
   * Handles all refreshes of the ad after initial load.
   * This should happen on gamTargetingRefreshes
   */
  useEffect(() => {
    const unsubscribe = $gamTargetingAtom.subscribe(() => {
      setShouldLoadAd(true);
    });
    return () => {
      unsubscribe();
    };
  }, [placement, sendToRequestBatchAds]);

  /**
   * Handles the initial load of the ad
   */
  useEffect(() => {
    if (shouldLoadAd && slotState && isInView) {
      updatePlacementKeyValueById(placementId, 'status', 'refresh');
      sendToRequestBatchAds(slotState);
      setShouldLoadAd(false);
    }
  }, [slotState, isInView, shouldLoadAd]);

  const getSlot = useCallback(
    (placement: GamPlacement) => {
      const placementConfig = placement.adServer.config;
      const sizes =
        (placementConfig.sizes as number[][]) ||
        (getSizesByMediaType(placementConfig.mediaTypes) as number[][]);

      const placementConfigPath = ensurePathStartsWithSlash(
        placementConfig.path
      );

      const slot = window.googletag
        .defineSlot(placementConfigPath, sizes, placement.placementId)
        .addService(window.googletag.pubads());

      const { targeting = [] } = placementConfig;
      if ($initialState.get().env === 'local') {
        targeting.push({ key: 'test', value: 'true' });
      }

      const { enableGamForecasting, enableGamTestCampaign } =
        getAllFeatureStatuses();

      if (enableGamForecasting && props.forecasting) {
        targeting.push({ key: 'forecasting', value: 'true' });
      }
      if (enableGamTestCampaign) {
        targeting.push({ key: 'gamTestCampaign', value: 'true' });
      }

      if (targeting?.length > 0) {
        setTargetingOnSlotOrGlobal({
          slot,
          targeting,
          global: false
        });
      }
      return slot;
    },
    [placementId, props.forecasting]
  );
  /**
   * Set up the ad
   */
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    if (placement && !slotState) {
      // TODO: Implement consent handling with getConsentStatusOrSubscribe, same as with Xandr?
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined and destroy it if necessary
        if (
          window.googletag
            .pubads()
            .getSlots()
            .some(
              (slot: any) => slot.getSlotElementId() === placement.placementId
            )
        ) {
          // slot already exists
          return;
        }
        debugLog('Defining Slot for placement: ', placementId);

        const slot = getSlot(placement);

        if (slot) {
          setSlotState(slot);
          updateMetricByKey(placementId, PlacementStatusesEnum.SlotCreated);
          debugLog('Gam successfully set-up for placement:', placementId);

          // Add event listeners for ad events
          setupGamEventListeners(slot);
        }
      });
    }
  }, [placement, gamConfig, placementId, getSlot, slotState]);

  return { placement };
}

function useShouldLoadAd({
  placementId,
  ref
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
}) {
  const inViewSettings = getInViewSettingsByBrand(placementId);

  const { isIntersecting } = useInScreen({ ref, ...(inViewSettings || {}) });

  return !inViewSettings || isIntersecting;
}
