// atomConstants.ts

import { setInitialPlacementStatusMetrics } from '@client/core/atoms/metrics';
import {
  AdConfigPlacement,
  AdServer,
  AfsConfig,
  AfsPlacement,
  ClientAdPlacement,
  InitialState,
  PageOpts,
  setAdvtState
} from '@schibsted-nmp/advertising-shared';

import { $afsAtom } from './afs';
import {
  $config,
  $initialState,
  $ppidAtom,
  getEmptyOrPersistedPPID
} from './config';
import { $gamTargetingAtom } from './gamTargeting';
import { $placementsMap, getPlacementList } from './placements';
import { $unleashFeaturesAtom } from './unleashFeatures';
import { $xandrPageOpts } from './xandrPageOpts';

export enum AtomChannels {
  Podlet = 'advertising-podlet'
}

export enum AtomTopics {
  PlacementsMap = 'placementsMap',
  PPID = 'ppid',
  Glimr = 'glimr',
  Cycle = 'cycle',
  GamTargeting = 'gamTargeting',
  PrebidLoadedList = 'prebidLoadedList',
  Keywords = 'keywords',
  Config = 'config',
  Afs = 'afs',
  PlacementStatusMetrics = 'placementStatusMetrics',
  UnleashFeatures = 'unleashFeatures',
  SearchFilters = 'searchFilters',
  InitialState = 'initialState',
  XandrPageOpts = 'xandrPageOpts',
  WaitForClient = 'waitForClient',
  PlacementMetricSequence = 'placementMetricSequence',
  AdStatusSequence = 'adStatusSequence',
  SignificantEvents = 'significantEvents'
}

export const setInitialGlobalAtoms = (initialState: InitialState) => {
  const gamTargeting = initialState?.config?.adServer?.gam?.targeting || [];

  $config.set(initialState?.config || {});
  $ppidAtom.set(getEmptyOrPersistedPPID());
  $gamTargetingAtom.set(gamTargeting);

  const placements =
    initialState?.config?.placements || ([] as AdConfigPlacement[]);

  const placementsMap: Record<string, ClientAdPlacement> = placements.reduce<
    Record<string, ClientAdPlacement>
  >(
    (map, placement) => {
      map[`${placement.placementId}`] = {
        ...placement,
        status: 'pending',
        creativeType: ''
      } as unknown as ClientAdPlacement;
      return map;
    },
    {} as Record<string, ClientAdPlacement>
  );

  $placementsMap.set(placementsMap);

  $afsAtom.set({
    config: initialState?.config?.adServer.afs || ({} as AfsConfig),
    placements: initialState?.config?.placements.filter(
      (placement) => placement.adServer.type === AdServer.AFS
    ) as AfsPlacement[]
  });
  setInitialPlacementStatusMetrics(getPlacementList());
  $unleashFeaturesAtom.set(initialState?.unleashFeatures || {});
  $initialState.set({
    env: initialState?.env,
    baseUrl: initialState?.baseUrl,
    logSessionId: initialState?.logSessionId
  });
  $xandrPageOpts.set(
    (initialState?.config?.adServer?.xandr?.pageOpts || {}) as PageOpts
  );
};

setAdvtState('state', () => ({
  initialState: $initialState.get(),
  config: $config.get(),
  ppid: $ppidAtom.get(),
  placements: $placementsMap.get(),
  afs: $afsAtom.get(),
  unleashFeatures: $unleashFeaturesAtom.get(),
  gamTargeting: $gamTargetingAtom.get(),
  xandrPageOpts: $xandrPageOpts.get()
}));
