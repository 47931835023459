import {
  setAdvtState,
  AdConfigPlacement
} from '@schibsted-nmp/advertising-shared';

/**
 * Used to console.log out everything related to the placements.
 * @param placements
 */
export function setupOverrideHelper(placements: AdConfigPlacement[]) {
  if (typeof window !== 'undefined') {
    const currentUrl = new URL(window.location.href);
    const overrideKey = 'ast_override_div';

    setAdvtState('getOverride', (creativeId: string) => {
      placements.forEach(({ placementId }) => {
        currentUrl.searchParams.set(
          overrideKey,
          `${placementId}:${creativeId}`
        );

        console.log(
          `%c Placement ${placementId} `,
          `color:hotpink; background:white;`,
          currentUrl.toString()
        );
      });
    });
  }
}
