import { triggerFinnAfsRequest } from '@client/adsense/finn-afs';
import { incrementCycleToRefreshBanners } from '@client/core/atoms/cycleAtom';
import {
  getPlacementList,
  setAllPlacementsToPending
} from '@client/core/atoms/placements';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import fetcher from '@client/core/utils/api/fetcher';
import { getPermissionValueIfExists } from '@client/core/utils/consent';
import { getInitialState } from '@client/core/utils/dom/getInitialState';
import { updateFeed } from '@client/xandr/feed';
import {
  AdConfig,
  AfsConfig,
  AfsPlacement,
  debugLog,
  Keywords,
  SearchConfigBody,
  SearchKey,
  SearchParams,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { LegacyConfig } from '@server/types/apiResponseTypes';

import { setPageOpts } from '../../xandr/xandr';
import { $afsAtom } from '../atoms/afs';
import { $config, $searchFilters, $waitForClient } from '../atoms/config';
import { setGamTargetingAtom } from '../atoms/gamTargeting';
import { addLifecycleEvent, setAllRefreshMetrics } from '../atoms/metrics';
import { setXandrPageOpts } from '../atoms/xandrPageOpts';

export type FilterUpdatePayloadType = {
  searchKey: SearchKey;
  searchParams: SearchParams;
};

type SearchConfigResponse = {
  data: {
    keywords: Keywords;
    afs?: AfsConfig;
  };
};

function shouldUpdateKeywords(): boolean {
  // If the search params have changed, we need to update the keywords.
  // Here we can do more specific checks such as update on pagination but do not on sort.

  const currentSearchParams = $searchFilters.get().current || {};
  if (Object.entries(currentSearchParams).length === 0) return false;

  return $searchFilters.get().previous !== currentSearchParams;
}

export const refreshKeywords = async (event) => {
  try {
    const payload = event.payload as FilterUpdatePayloadType;
    debugLog(`Received search filter update:`, payload);

    $searchFilters.set({
      previous: $searchFilters.get().previous,
      current: payload.searchParams
    });

    if (shouldUpdateKeywords()) {
      const { brand, deviceType, vertical, subvertical } = $config.get();
      const consent = getPermissionValueIfExists();
      const body = {
        logSessionId: getInitialState()?.logSessionId || '',
        searchKey: payload.searchKey,
        searchParams: payload.searchParams,
        deviceType,
        consent,
        vertical,
        brand,
        subvertical
      } as SearchConfigBody;

      // Determine if the advertising config APIs are disabled
      const disableAdvertisingConfigApi = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.disableAdvertisingConfigApi
      );

      const disableLegacyAdvertisingConfigApi = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.disableLegacyAdvertisingConfigApi
      );

      // Create promises conditionally
      const fetchSearchFilterConfigPromise = disableAdvertisingConfigApi
        ? Promise.resolve({
            data: null,
            error: ''
          })
        : fetcher<{
            data: AdConfig;
            error: string;
          }>('/api/update/targeting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          });

      const fetchLegacySearchFilterConfigPromise =
        disableLegacyAdvertisingConfigApi
          ? Promise.resolve({
              data: null,
              error: ''
            })
          : fetcher<SearchConfigResponse>('/api/legacy/search-config', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            });

      // Run the fetchers in parallel
      const [searchFilterConfig, legacySearchFilterConfig] = await Promise.all([
        fetchSearchFilterConfigPromise,
        fetchLegacySearchFilterConfigPromise
      ]);

      addLifecycleEvent('Refresh targeting config after filter update');

      const filterData = searchFilterConfig?.data as AdConfig;

      setAllPlacementsToPending();
      setAllRefreshMetrics(getPlacementList());

      if (filterData?.adServer?.gam) {
        setGamTargetingAtom(filterData.adServer.gam.targeting);
      }

      if (filterData?.adServer?.afs) {
        debugLog(`Sending AFS config to AFS ad vendor`, {
          afs: filterData?.adServer?.afs
        });
        $afsAtom.set({
          config: filterData?.adServer?.afs as AfsConfig,
          placements: filterData?.placements as AfsPlacement[]
        });
      }

      if (brand === 'finn') {
        triggerFinnAfsRequest();
      }

      const legacyFilterData = legacySearchFilterConfig?.data as LegacyConfig;
      if (legacyFilterData?.keywords) {
        if (legacyFilterData?.feed) {
          updateFeed(legacyFilterData.feed);
        }

        setXandrPageOpts({
          keywords: legacyFilterData?.keywords
        });

        if (legacyFilterData?.keywords) {
          setPageOpts({ keywords: legacyFilterData?.keywords }, () => {
            // This shouldn't be done if e.g. no ads have been loaded yet when this is called
            if (!$waitForClient.get()) {
              incrementCycleToRefreshBanners();
            }
          });
        }

        $searchFilters.set({
          previous: payload.searchParams,
          current: $searchFilters.get().current
        });
      }
    }
  } catch (error) {
    console.error(`Error in search-config API fetch: ${error}`);
  }
  return true;
};
